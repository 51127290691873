<template>
    <div class="text-grey8 bg-grey300">
        <div class="grid grid-cols-12 gap-6 py-[1.875rem] sm:pb-24 max-w-all-sizes">
            <div class="col-span-3 sm:col-span-12">
                <logos-full class="text-black w-[4.74rem] mb-[.756rem]"></logos-full>

                <nav class="flex flex-col text-[0.875rem] leading-7 -tracking-[.03rem]">
                    <ul>
                        <li>
                            <nuxt-link class="hover:text-tangerine" :to="{name: 'simulation'}">
                                Investir
                            </nuxt-link>
                        </li>

                        <li>
                            <nuxt-link class="hover:text-tangerine" :to="{name: 'service-cle-en-main'}">
                                Nos services
                            </nuxt-link>
                        </li>

                        <li>
                            <nuxt-link class="hover:text-tangerine" :to="{name: 'success-story'}">
                                Cas clients
                            </nuxt-link>
                        </li>

                        <li>
                            <a class="hover:text-tangerine" :href="runtimeConfig.blogUrl + '/ressources'">
                                Ressources
                            </a>
                        </li>

                        <li>
                            <a class="hover:text-tangerine" :href="runtimeConfig.blogUrl + '/offre/le-score-maslow-le-calcul-de-notre-indice-de-confiance/'">
                                Notre approche
                            </a>
                        </li>

                        <li>
                            <nuxt-link class="hover:text-tangerine" :to="{name: 'nous-contacter'}">
                                Contact
                            </nuxt-link>
                        </li>
                    </ul>
                </nav>
            </div>

            <div class="col-span-3 sm:col-span-12">
                <div class="text-[.750rem] text-black font-semibold tracking-[.03em] uppercase mb-3">
                    À propos
                </div>

                <ul class="flex flex-col text-[0.875rem] leading-7 tracking-[-.03rem]">
                    <li>
                        <a class="hover:text-tangerine" :href="runtimeConfig.blogUrl + '/foire-aux-questions/la-plateforme-maslow/'">
                            Foire aux questions
                        </a>
                    </li>

                    <li>
                        <nuxt-link class="hover:text-tangerine" :to="{name: 'a-propos'}">
                            Qui sommes-nous ?
                        </nuxt-link>
                    </li>

                    <li>
                        <nuxt-link class="hover:text-tangerine" :to="{path: '/cgu/'}">
                            Conditions générales d'utilisation
                        </nuxt-link>
                    </li>

                    <li>
                        <nuxt-link class="hover:text-tangerine" :to="{path: '/mentions-legales/'}">
                            Mentions légales
                        </nuxt-link>
                    </li>

                    <li>
                        <nuxt-link class="hover:text-tangerine" :href="runtimeConfig.blogUrl + '/author'">
                            Nos auteurs experts
                        </nuxt-link>
                    </li>
                </ul>
            </div>

            <div class="col-span-3 sm:col-span-12">
                <div class="text-[.750rem] text-black font-semibold tracking-[.03em] uppercase mb-3">
                    Suivez-nous
                </div>

                <div>
                    <ul class="flex flex-col text-[0.875rem] leading-7 tracking-[-.03rem]">
                        <li>
                            <a class="hover:text-tangerine" :href="runtimeConfig.blogUrl + '/podcast-immobilier'">
                                Podcasts
                            </a>
                        </li>
                    </ul>

                    <ul class="flex flex-col text-[0.875rem] leading-7 tracking-[-.03rem]">
                        <li>
                            <a class="hover:text-tangerine" :href="runtimeConfig.blogUrl">
                                Blog
                            </a>
                        </li>
                    </ul>

                    <ul class="flex flex-col text-[0.875rem] leading-7 tracking-[-.03rem]">
                        <li>
                            <nuxt-link class="hover:text-tangerine" :to="{path: '/ils-parlent-de-nous'}">
                                Ils parlent de nous
                            </nuxt-link>
                        </li>
                    </ul>
                </div>

                <div class="flex items-baseline gap-2 mt-4">
                    <div>
                        <a aria-label="intagram" :href="urlReseaux.instagram" target="_blank">
                            <icons-instagram class="reseaux"></icons-instagram>
                        </a>
                    </div>

                    <div>
                        <a aria-label="linkedin" :href="urlReseaux.linkedIn" target="_blank">
                            <icons-linked-in class="reseaux"></icons-linked-in>
                        </a>
                    </div>

                    <div>
                        <a aria-label="tiktok" :href="urlReseaux.tiktok" target="_blank">
                            <icons-tiktok class="reseaux mb-[-0.07rem]"></icons-tiktok>
                        </a>
                    </div>

                    <div>
                        <a aria-label="Youtube" :href="urlReseaux.youtube" target="_blank">
                            <icons-youtube class="reseaux mb-[-0.175rem]"></icons-youtube>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-span-3 sm:col-span-12">
                <div class="text-[.750rem] text-black font-semibold tracking-[.03em] uppercase mb-4">
                    Nous contacter
                </div>

                <div class="text-[.875rem] -tracking-[.03em]">
                    <div class="font-bold">
                        Maslow
                    </div>

                    <div class="mb-6">
                        Groupe Valority
                    </div>

                    <div>
                        94 quai Charles de Gaulle <br>
                        69006 Lyon <br>
                        09 70 83 38 34
                    </div>
                </div>

                <div class="flex items-center mt-4">
                    <c-button :to="{name:'nous-contacter-prendre-rendez-vous'}" class="text-black rounded border border-black hover:bg-grey300">
                        Prendre rendez-vous
                    </c-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const runtimeConfig = computed(() => {
    return useRuntimeConfig().public;
});

const urlReseaux = computed(() => {
    return getUrlReseaux();
});
</script>

<style scoped lang="scss">
.reseaux {
    @apply w-4 h-4 text-black hover:text-tangerine cursor-pointer;
}
</style>

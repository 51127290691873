
import * as narcisseRuntime$E0pmlPz8uR from '/app/providers/narcisse.js'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "narcisse",
  "domains": [
    "tethys.valority.com",
    "tethys-qualif.valority.com",
    "tethys.valeur-et-capital.localhost",
    "api.maslow.immo",
    "api.qualif.maslow.immo",
    "maslow-api.valeur-et-capital.localhost",
    "capacite-emprunt.valority.com",
    "capacite-emprunt-qualif.valority.com",
    "capacite-emprunt.valeur-et-capital.localhost"
  ],
  "alias": {},
  "densities": [
    1,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['narcisse']: { provider: narcisseRuntime$E0pmlPz8uR, defaults: undefined }
}
        
<template>
    <header class="flex flex-col sm:h-menu-sm md:h-menu-sm z-[9998]">
        <!-- BANDEAU AU DESSUS -->
        <HeaderMessageCompte :message="message"></HeaderMessageCompte>

        <!-- Menu App -->
        <div class="px-6 sm:px-2 flex items-center grow md:h-full bg-white sm:h-full">

            <!-- Logo Left -->
            <div class="flex items-center h-full">
                <button class="hidden sm:block md:block" @click.stop="toggleMenu" ref="menuButton">
                    <icons-menu-outline v-if="!displayMenu" class="text-black w-8 h-8 mr-3.5"></icons-menu-outline>
                    <icons-x-outline v-else class="text-black w-8 h-8 mr-3.5"></icons-x-outline>
                </button>

                <NuxtLink class="cursor-pointer flex items-center" :to="{name: 'index'}" aria-label="Accueil">
                    <logos-full class="text-black w-[4.5rem] h-5"></logos-full>
                </NuxtLink>
            </div>

            <!-- Menu burger mobile -->
            <transition name="slide-fade">
                <div v-if="displayMenu"
                    v-click-outside="closeMenu"
                    class="fixed hidden sm:flex md:flex flex-col top-0 left-0 bg-white pt-3 w-full min-h-screen md:w-1/2 md:shadow-2xl touch-none z-10"
                    ref="menuLeft"
                >
                    <div class="flex justify-between items-center pl-1 pr-2">
                        <div class="flex gap-x-[0.6rem] items-center">
                            <icons-x-outline class="text-black w-10 h-10" @click="toggleMenu"></icons-x-outline>

                            <NuxtLink class="cursor-pointer flex items-center" :to="{name: 'index'}" aria-label="Accueil">
                                <logos-full class="text-black w-[4.5rem] h-8"></logos-full>
                            </NuxtLink>
                        </div>

                        <c-button class="hidden sm:block ml-6 mr-2 bg-tangerine text-white text-body-14 z-[11]" :to="{name: 'nous-contacter-prendre-rendez-vous'}">
                            Prendre rendez-vous
                        </c-button>
                    </div>

                    <ul class="mt-8 font-medium w-fit px-4 list-none">
                        <li class="text-xl leading-[3.125rem]">
                            <div @click.stop="selectedMenu('investir')"
                                 :class="{'font-semibold': selected === 'investir'}"
                                 class="md:flex md:items-center md:gap-x-2 sm:flex sm:items-center"
                            >
                                Investir
                                <icons-chevron-down-solid v-if="selected !== 'investir'" class="w-6 sm:ml-2 sm:w-7"></icons-chevron-down-solid>
                                <icons-chevron-up-solid v-else class="w-6 sm:ml-2 sm:w-7"></icons-chevron-up-solid>
                            </div>

                            <c-accordeon :open-accordeon="selected === 'investir'" time=".3s">
                                <ul class="text-lg leading-10 ml-6 list-none">
                                    <li class="md:flex md:items-center">
                                        <icons-adjustments-outline class="mr-3 w-6 h-6 sm:hidden"></icons-adjustments-outline>

                                        <NuxtLink :to="{name: 'je-me-lance'}" active-class="font-semibold">
                                            Recherche accompagnée
                                        </NuxtLink>
                                    </li>

                                    <li class="md:flex md:items-center">
                                        <icons-search-outline class="mr-3 w-6 h-6 sm:hidden"></icons-search-outline>

                                        <NuxtLink :to="{name: 'catalogue'}" active-class="font-semibold">
                                            Tous nos biens
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </c-accordeon>
                        </li>

                        <li class="text-xl leading-[3.125rem]">
                            <div @click.stop="selectedMenu('service-cle-en-main')"
                                 :class="{'font-semibold': selected === 'service-cle-en-main'}"
                                 class="md:flex md:items-center md:gap-x-2 sm:flex sm:items-center"
                            >
                                Nos services
                                <icons-chevron-down-solid v-if="selected !== 'service-cle-en-main'" class="w-6 sm:ml-2 sm:w-7"></icons-chevron-down-solid>
                                <icons-chevron-up-solid v-else class="w-6 sm:ml-2 sm:w-7"></icons-chevron-up-solid>
                            </div>

                            <c-accordeon :open-accordeon="selected === 'service-cle-en-main'">
                                <ul class="text-lg leading-10 ml-6 list-none">
                                    <li>
                                        <NuxtLink :to="{name: 'service-cle-en-main-tous-nos-services-en-un-coup-d-oeil'}" exact-active-class="font-semibold">
                                            Tous nos services en un coup d'oeil
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="{name: 'service-cle-en-main-definir-mon-profil'}" active-class="font-semibold">
                                           1. Définir mon profil investisseur
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="{name: 'service-cle-en-main-choisir-et-reserver'}" active-class="font-semibold">
                                           2. Choisir et réserver mon bien
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="{name: 'service-cle-en-main-financer'}" active-class="font-semibold">
                                           3. Financer mon investissement
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="{name: 'service-cle-en-main-suivre-la-livraison'}" active-class="font-semibold">
                                           4. Suivre la livraison de mon bien
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="{name: 'service-cle-en-main-percevoir-mes-loyers'}" active-class="font-semibold">
                                           5. Percevoir mes loyers
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="{name: 'service-cle-en-main-piloter-mon-investissement'}" active-class="font-semibold">
                                           6. Piloter mon investissement
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <a :href="runtimeConfig.blogUrl + '/faq/la-plateforme-maslow/'" @click="selectedMenu('faq')"
                                           :class="{'font-semibold': selected === 'faq'}"
                                        >
                                            Foire aux questions
                                        </a>
                                    </li>

                                    <li>
                                        <NuxtLink :to="{name: 'ils-parlent-de-nous'}" active-class="font-semibold">
                                            Ils parlent de nous
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </c-accordeon>
                        </li>

                        <li class="text-xl leading-[3.125rem]">
                            <div>
                                <NuxtLink :to="{name: 'success-story'}" exact-active-class="font-semibold">
                                    Cas clients
                                </NuxtLink>
                            </div>
                        </li>

                        <li class="text-xl leading-[3.125rem]">
                            <div @click.stop="selectedMenu('ressources')"
                                 :class="{'font-semibold': selected === 'ressources'}"
                                 class="md:flex md:items-center md:gap-x-2 sm:flex sm:items-center"
                            >
                                Ressources
                                <icons-chevron-down-solid v-if="selected !== 'ressources'" class="w-6 sm:ml-2 sm:w-7"></icons-chevron-down-solid>
                                <icons-chevron-up-solid v-else class="w-6 sm:ml-2 sm:w-7"></icons-chevron-up-solid>
                            </div>

                            <c-accordeon :open-accordeon="selected === 'ressources'">
                                <ul class="text-lg leading-10 ml-6 list-none">
                                    <li>
                                        <NuxtLink :to="runtimeConfig.blogUrl" exact-active-class="font-semibold">
                                            Blog
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="{name: 'catalogue'}" active-class="font-semibold">
                                            Où investir
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="runtimeConfig.blogUrl + '/publications'" active-class="font-semibold">
                                            Publications
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="runtimeConfig.blogUrl + '/podcast-immobilier'" active-class="font-semibold">
                                            Podcasts
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </c-accordeon>
                        </li>

                        <li class="text-xl leading-[3.125rem]">
                            <div @click.stop="selectedMenu('notreApproche')"
                                 :class="{'font-semibold': selected === 'notreApproche'}"
                                 class="md:flex md:items-center md:gap-x-2 sm:flex sm:items-center"
                            >
                                Notre approche
                                <icons-chevron-down-solid v-if="selected !== 'notreApproche'" class="w-6 sm:ml-2 sm:w-7"></icons-chevron-down-solid>
                                <icons-chevron-up-solid v-else class="w-6 sm:ml-2 sm:w-7"></icons-chevron-up-solid>
                            </div>

                            <c-accordeon :open-accordeon="selected === 'notreApproche'">
                                <ul class="text-lg leading-10 ml-6 list-none">
                                    <li>
                                        <NuxtLink :to="{name: 'a-propos'}" exact-active-class="font-semibold">
                                            Qui sommes-nous ?
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="{name: 'a-propos-equipe'}" active-class="font-semibold">
                                            Notre équipe
                                        </NuxtLink>
                                    </li>

                                    <li>
                                        <NuxtLink :to="runtimeConfig.blogUrl + '/reserver-son-bien'" active-class="font-semibold">
                                            L'offre Maslow
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </c-accordeon>
                        </li>

                        <li class="text-xl leading-[3.125rem]">
                            <div>
                                <NuxtLink :to="{name: 'nous-contacter-poser-une-question'}" exact-active-class="font-semibold">
                                    Contact
                                </NuxtLink>
                            </div>
                        </li>

                        <li class="mt-12">
                            <div class="flex items-center divide-x-2 divide-grey4">
                                <NuxtLink :to="{name: isLoggedIn ? 'mon-compte-mon-panier' : 'connexion'}"
                                          class="flex gap-x-1 items-center hover:text-tangerine pr-2"
                                >
                                    <icons-shopping-cart-alt-outline :active="useUserStore().hasInShoppingCart" class="w-5"></icons-shopping-cart-alt-outline>
                                    <span class="block">Mon panier</span>
                                </NuxtLink>

                                <NuxtLink :to="{name: isLoggedIn ? 'mon-compte' : 'connexion'}"
                                          class="flex gap-x-1 items-center hover:text-tangerine pl-2"
                                >
                                    <icons-user-outline class="w-5"></icons-user-outline>
                                    <span class="block">{{ isLoggedIn ? prenom : 'Mon compte' }}</span>
                                </NuxtLink>
                            </div>
                        </li>

                        <li>
                            <div class="flex gap-x-3 items-center mt-12">
                                <a aria-label="instagram" :href="urls.instagram" target="_blank">
                                    <icons-instagram class="reseaux"></icons-instagram>
                                </a>

                                <a aria-label="linkedin" :href="urls.linkedIn" target="_blank">
                                    <icons-linked-in class="reseaux"></icons-linked-in>
                                </a>

                                <a aria-label="tiktok" :href="urls.tiktok" target="_blank">
                                    <icons-tiktok class="reseaux"></icons-tiktok>
                                </a>

                                <a aria-label="youtube" :href="urls.youtube" target="_blank">
                                    <icons-youtube class="reseaux"></icons-youtube>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </transition>

            <!-- MENU DESKTOP-->
            <nav class="h-full md:hidden sm:hidden ml-16">
                <ul class="flex flex-row items-center text-sm h-full whitespace-nowrap">
                    <li class="mr-10 relative font-medium cursor-default leading-[4rem] hover:text-tangerine transition-colors"
                        @mouseenter="openMenu('investir')"
                        @mouseleave="closeMenu"
                    >
                        <NuxtLink :to="{name: 'catalogue'}" exact-active-class="link-is-exact-active"
                                  class="flex items-center">
                            Investir
                            <icons-chevron-down-solid class="w-5 h-5"></icons-chevron-down-solid>
                        </NuxtLink>

                        <Transition>
                            <div class="!text-black absolute flex gap-x-4 top-16 left-[-3.5rem] bg-white text-[.875rem] leading-7 tracking-[-.02em] px-6 py-[1.5rem] rounded-b-[.625rem] z-[2]"
                                v-show="selected === 'investir'"
                            >
                                <div class="flex gap-x-4">
                                    <menu-card
                                        v-for="card in defaultCard['investir']"
                                        :sub-title="card.subtitle"
                                        :image="card.image"
                                        :to="card.to"
                                        :config="card.config"
                                    ></menu-card>
                                </div>
                            </div>
                        </Transition>
                    </li>

                    <li class="mr-10 relative font-medium cursor-default lg:leading-[4rem] hover:text-tangerine transition-colors"
                        @mouseenter="openMenu('service-cle-en-main')"
                        @mouseleave="closeMenu"
                    >
                        <NuxtLink :to="{name: 'service-cle-en-main-tous-nos-services-en-un-coup-d-oeil'}"
                                  exact-active-class="link-is-exact-active"
                                  active-class="link-is-active"
                                  class="flex items-center"
                        >
                            Nos services
                            <icons-chevron-down-solid class="w-5 h-5"></icons-chevron-down-solid>
                        </NuxtLink>

                        <Transition>
                            <div class="!text-black absolute flex gap-x-10 top-16 left-[-8.5rem] bg-white text-[.875rem] leading-7 tracking-[-.02em] pl-5 pr-12 py-6 rounded-b-[.625rem] z-[2] whitespace-nowrap"
                                v-show="selected === 'service-cle-en-main'"
                            >
                                <menu-card :sub-title="defaultCard['service-cle-en-main']?.subtitle"
                                           :image="defaultCard['service-cle-en-main']?.image"
                                           :to="defaultCard['service-cle-en-main']?.to"
                                           :config="defaultCard['service-cle-en-main']?.config"
                                >
                                </menu-card>

                                <div>
                                    <div class="text-grey7">
                                        NOS SERVICES EN DÉTAIL
                                    </div>

                                    <ul class="mt-4 ml-2 list-none">
                                        <li class="cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="{name: 'service-cle-en-main-definir-mon-profil'}"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-user-outline class="w-5 h-5 text-tangerine"></icons-user-outline>
                                                1. Définir mon profil investisseur
                                            </NuxtLink>
                                        </li>

                                        <li class="cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="{name: 'service-cle-en-main-choisir-et-reserver'}"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-badge-check-outline
                                                    class=" w-5 h-5 text-tangerine"></icons-badge-check-outline>
                                                2. Choisir et réserver mon bien
                                            </NuxtLink>
                                        </li>

                                        <li class="flex cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="{name: 'service-cle-en-main-financer'}"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-calculator-outline
                                                    class="w-5 h-5 text-tangerine"></icons-calculator-outline>
                                                3. Financer mon investissement
                                            </NuxtLink>
                                        </li>

                                        <li class="flex cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="{name: 'service-cle-en-main-suivre-la-livraison'}"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-key-outline class="w-5 h-5 text-tangerine"></icons-key-outline>
                                                4. Suivre la livraison de mon bien
                                            </NuxtLink>
                                        </li>

                                        <li class="flex cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="{name: 'service-cle-en-main-percevoir-mes-loyers'}"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-currency-euro-outline
                                                    class="w-5 h-5 text-tangerine"></icons-currency-euro-outline>
                                                5. Percevoir mes loyers
                                            </NuxtLink>
                                        </li>

                                        <li class="flex cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="{name: 'service-cle-en-main-piloter-mon-investissement'}"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-trending-up-outline
                                                    class="w-5 h-5 text-tangerine"></icons-trending-up-outline>
                                                6. Piloter mon investissement
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </div>

                                <div>
                                    <div class="text-grey7">
                                        POUR ALLER PLUS LOIN
                                    </div>

                                    <ul class="mt-4 ml-2">
                                        <li class="flex items-center cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <a :href="runtimeConfig.blogUrl + '/faq/la-plateforme-maslow/'" class="flex items-center gap-2">
                                                <icons-chat-outline class="w-5 h-5 text-tangerine"></icons-chat-outline>
                                                Foire aux questions
                                            </a>
                                        </li>

                                        <li class="flex items-center cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="{name: 'ils-parlent-de-nous'}"
                                                      active-class="font-semibold"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-newspaper-outline class="w-5 h-5 text-tangerine"></icons-newspaper-outline>
                                                Ils parlent de nous
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Transition>
                    </li>

                    <li class="mr-10 font-medium cursor-pointer hover:text-tangerine transition-colors">
                        <NuxtLink :to="{name: 'success-story'}" active-class="link-is-active">
                            Cas clients
                        </NuxtLink>
                    </li>

                    <li class="mr-10 relative font-medium cursor-default lg:leading-[4rem] hover:text-tangerine transition-colors"
                        @mouseenter="openMenu('ressources')"
                        @mouseleave="closeMenu"
                    >
                        <a :href="runtimeConfig.blogUrl + '/ressources'" class="flex items-center">
                            Ressources
                            <icons-chevron-down-solid class="w-5 h-5"></icons-chevron-down-solid>
                        </a>


                        <Transition name="slide-enter">
                            <div class="!text-black absolute flex gap-x-12 top-[4rem] bg-white
                                text-[.875rem] leading-7 tracking-[-.02em] pl-6 pr-12 ml-12 py-4 rounded-b-[.625rem]
                                z-[2] whitespace-nowrap -translate-x-1/2"
                                 v-show="selected === 'ressources'"
                            >
                                <div class="flex gap-x-8" v-if="menuConfiguration">
                                    <menu-card
                                        v-for="card in menuConfiguration['ressources']"
                                        :sub-title="card.subtitle"
                                        :title="card.title"
                                        :image="card.image"
                                        :to="card.to"
                                        :date="card.date">
                                    </menu-card>
                                </div>

                                <div>
                                    <div class="text-grey7">
                                        RESSOURCES
                                    </div>

                                    <ul class="mt-2 ml-2 list-none">
                                        <li class="cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="runtimeConfig.blogUrl" class="flex items-center gap-2">
                                                <icons-bell-alert-outline
                                                    class="w-5 h-5 text-tangerine"></icons-bell-alert-outline>
                                                Blog
                                            </NuxtLink>
                                        </li>

<!--                                        TODO: en attendant que le contenu soit créé par le WM-->
<!--                                        <li class="cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">-->
<!--                                            <NuxtLink :to="{name: 'catalogue'}"-->
<!--                                                      exact-active-class="link-is-exact-active"-->
<!--                                                      class="flex items-center gap-2"-->
<!--                                            >-->
<!--                                                <icons-map-pin-outline-->
<!--                                                    class=" w-5 h-5 text-tangerine"></icons-map-pin-outline>-->
<!--                                                Où investir-->
<!--                                            </NuxtLink>-->
<!--                                        </li>-->

                                        <li class="flex cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="runtimeConfig.blogUrl + '/publications'"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-document-text-outline class="w-5 h-5 text-tangerine"></icons-document-text-outline>
                                                Publications
                                            </NuxtLink>
                                        </li>

                                        <li class="flex cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="runtimeConfig.blogUrl + '/podcast-immobilier'"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-microphone-outline class="w-5 h-5 text-tangerine"></icons-microphone-outline>
                                                Podcasts
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Transition>
                    </li>

                    <li class="mr-10 relative font-medium cursor-pointer lg:leading-[4rem] hover:text-tangerine transition-colors"
                        @mouseenter="openMenu('notreApproche')"
                        @mouseleave="closeMenu"
                    >
                        <a class="flex items-center">
                            Notre approche
                            <icons-chevron-down-solid class="w-5 h-5"></icons-chevron-down-solid>
                        </a>

                        <Transition name="slide-enter">
                            <div class="!text-black absolute flex gap-x-12 top-[4rem] bg-white
                            text-[.875rem] leading-7 tracking-[-.02em] pl-6 pr-12 ml-12 py-[1.5rem] rounded-b-[.625rem]
                            z-[2] whitespace-nowrap -translate-x-1/2"
                                 v-show="selected === 'notreApproche'"
                            >
                                <div class="flex gap-x-4">
                                    <menu-card v-for="card in menuConfiguration?.notreApproche"
                                               :sub-title="card.subtitle"
                                               :title="card.title"
                                               :image="card.image"
                                               :to="card.to"
                                               :date="card.date"
                                    >
                                    </menu-card>
                                </div>

                                <div>
                                    <div class="text-grey7">
                                        À PROPOS
                                    </div>

                                    <ul class="mt-3 ml-2 list-none">
                                        <li class="cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="{name: 'a-propos'}"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-hand-raised class="w-5 h-5 text-tangerine"></icons-hand-raised>
                                                Qui sommes-nous ?
                                            </NuxtLink>
                                        </li>

                                        <li class="cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="{name: 'a-propos-equipe'}"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-users-outline class=" w-5 h-5 text-tangerine"></icons-users-outline>
                                                Notre équipe
                                            </NuxtLink>
                                        </li>

                                        <li class="flex cursor-pointer hover:text-tangerine font-medium mt-2 transition-colors">
                                            <NuxtLink :to="runtimeConfig.blogUrl + '/offre-maslow/reserver-son-bien'"
                                                      exact-active-class="link-is-exact-active"
                                                      class="flex items-center gap-2"
                                            >
                                                <icons-eye-outline class="w-5 h-5 text-tangerine"></icons-eye-outline>
                                                L'offre Maslow
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Transition>
                    </li>

                    <li class="mr-10 font-medium cursor-pointer hover:text-tangerine transition-colors">
                        <nuxt-link :to="{name: 'nous-contacter'}" active-class="isActive">
                            Contact
                        </nuxt-link>
                    </li>
                </ul>
            </nav>

            <!-- Logo Right -->
            <div class="flex ml-auto items-center" :class="{'hidden': displayMenu && isMobile}">
                <div class="flex gap-x-3 items-center mr-1 md:hidden sm:hidden">
                    <nuxt-link aria-label="instagram" :href="urls.instagram" target="_blank">
                        <icons-instagram class="reseaux"></icons-instagram>
                    </nuxt-link>

                    <nuxt-link aria-label="linkedin" :href="urls.linkedIn" target="_blank">
                        <icons-linked-in class="reseaux"></icons-linked-in>
                    </nuxt-link>

                    <nuxt-link aria-label="tiktok" :href="urls.tiktok" target="_blank">
                        <icons-tiktok class="reseaux"></icons-tiktok>
                    </nuxt-link>

                    <nuxt-link aria-label="youtube" :href="urls.youtube" target="_blank">
                        <icons-youtube class="reseaux"></icons-youtube>
                    </nuxt-link>
                </div>

                <c-button class="ml-6 mr-2 bg-tangerine text-white text-body-14 md:my-2" :to="{name: 'nous-contacter-prendre-rendez-vous'}">
                    Prendre rendez-vous
                </c-button>
            </div>
        </div>
    </header>
</template>

<script setup>
import {useUserStore} from '~/store/user';
import {storeToRefs} from "pinia";
import {getUrlReseaux} from "@/composables/urlMixin";
import {useEditorParser} from "~/composables/stringMixin";
import HeaderMessageCompte from "~/components/HeaderMessageCompte.vue";

const userStore = useUserStore();
const route = useRoute();
const urls = getUrlReseaux();

const {isLoggedIn, prenom} = storeToRefs(userStore);
const isMobile = toRef(useNuxtApp(), '$isMobile');

const menuLeft = ref(null);
const menuButton = ref(null);
const displayMenu = ref(false);
const selected = ref(null);

const {data: menuConfiguration} = await useAsyncData(
    'menuFetching',
    async () => {
        const response = await useNuxtApp().$axios.get("config/menu");

        return response.data;
    }
);

const defaultCard = computed(() => {
    return {
        'investir': [
            {subtitle: 'Calculer ma capacité d\'emprunt', to: {name: 'je-me-lance'} , image: "investir/capacite.svg", config: {fullSize: false, imageHeight: '9.5rem'}},
            {subtitle: 'Catalogue des appartements', to: {name: 'catalogue'}, image: 'investir/catalogue.webp', config: {imageHeight: '10rem'}}
        ],
        'service-cle-en-main': {
            subtitle: 'Tous nos services en un coup d’oeil',
            to: {name: 'service-cle-en-main-tous-nos-services-en-un-coup-d-oeil'},
            image: 'services/toutServices.webp',
            config: {imageHeight: '11rem'}
        }
    };
});

watch(() => route.name, (value) => {
    if (screen !== undefined && useNuxtApp().$isMobile.value === true) {
        displayMenu.value = false;
        selected.value = value.includes(selected.value) ? selected.value : false;
    }
}, {deep: true});


const runtimeConfig = computed(() => {
    return useRuntimeConfig().public;
});

function selectedMenu(type) {
    selected.value = selected.value === type ? null : type;
}

const message = computed(() => {
    let message = "";

    if (menuConfiguration.value) {
        if ("message" in menuConfiguration.value) {
            message = useEditorParser(menuConfiguration.value.message);
        }
    }

    return message;
});

function toggleMenu() {
    displayMenu.value = !displayMenu.value;
}

function openMenu(type) {
    selected.value = type;
}

function closeMenu() {
    displayMenu.value = false;
    selected.value = false;
}
</script>

<style scoped lang="scss">
.slide-fade-enter-active {
    transition: all .3s ease;
    opacity: 1;
}

.slide-fade-leave-active {
    transition: all .3s ease-out;
}

.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-100vw);
    opacity: 0;
}

.link-is-active, .link-is-exact-active {
    color: #F56E58;
}

.reseaux {
    @apply w-6 h-5 text-black hover:text-tangerine cursor-pointer transition-colors;
}

.v-enter-active,
.v-leave-active {
    transition: transform 0.1s ease;
    transform: scaleY(1) translateY(0%);
}

.v-enter-from,
.v-leave-to {
    transform: scaleY(0) translateY(-100%);
}

.slide-enter-enter-active,
.slide-enter-leave-active {
    transition: transform 0.1s ease;
    transform: scaleY(1) translateY(0%) translateX(-50%);
}

.slide-enter-enter-from,
.slide-enter-leave-to {
    transform: scaleY(0) translateY(-100%) translateX(-50%);
}
</style>

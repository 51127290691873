export function setLocalStorageValue(key, value) {
    if (!process.server) {
        localStorage.setItem(key, value);
    }
}

export function getLocalStorageValue(key, defaultValue = null) {
    if (!process.server) {
        const value = localStorage.getItem(key);

        if (value) {
            return value;
        } else if (defaultValue != null) {
            setLocalStorageValue(key, defaultValue);
            return defaultValue;
        }
    }

    return null;
}

export function // seulement celles qui commencent par 'simu'
getAllLocalStorageValues(type = 'simu') {
    let items = {};

    if (!process.server) {
        let reg = new RegExp(`^${type}`, "g");
        let keys = Object.keys(localStorage);

        for (let i = 0; i < keys.length; i++) {
            if (keys[i].match(reg)) {
                items[keys[i]] = localStorage.getItem(keys[i]);
            }
        }
    }

    return items;
}

export function removeLocalStorageValue(key) {
    if (!process.server) {
        if (localStorage.hasOwnProperty(key)) {
            localStorage.removeItem(key);
        }
    }
}

import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_mitt_qHtFhyHExd from "/app/plugins/00.mitt.js";
import _02_sentry_client_cl2lUVEp8i from "/app/plugins/02.sentry.client.js";
import _03_directives_PVtwrzndE6 from "/app/plugins/03.directives.js";
import _04_vee_validate_EW3t593Xh6 from "/app/plugins/04.vee-validate.js";
import _05_responsive_AdmVTpB2g5 from "/app/plugins/05.responsive.js";
import _06_toastr_client_FU2zQVpuJ4 from "/app/plugins/06.toastr.client.js";
import _07_token_2bhfqZxMsO from "/app/plugins/07.token.js";
import _08_axios_Kdx1dN8GWh from "/app/plugins/08.axios.js";
import _09_client_setup_client_xp5fBHZlK0 from "/app/plugins/09.client-setup.client.js";
import _10_router_scrollBehavior_client_2H9KqVusUk from "/app/plugins/10.router.scrollBehavior.client.js";
import _12_socket_client_hArx9nsWLm from "/app/plugins/12.socket.client.js";
import lottie_player_client_iQljhlLaR2 from "/app/plugins/lottie-player.client.js";
import moment_lgOtqBZZj3 from "/app/plugins/moment.js";
import vue_tel_input_GukcJ5wu6r from "/app/plugins/vue-tel-input.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  plugin_tbFNToZNim,
  chunk_reload_client_UciE0i6zes,
  _00_mitt_qHtFhyHExd,
  _02_sentry_client_cl2lUVEp8i,
  _03_directives_PVtwrzndE6,
  _04_vee_validate_EW3t593Xh6,
  _05_responsive_AdmVTpB2g5,
  _06_toastr_client_FU2zQVpuJ4,
  _07_token_2bhfqZxMsO,
  _08_axios_Kdx1dN8GWh,
  _09_client_setup_client_xp5fBHZlK0,
  _10_router_scrollBehavior_client_2H9KqVusUk,
  _12_socket_client_hArx9nsWLm,
  lottie_player_client_iQljhlLaR2,
  moment_lgOtqBZZj3,
  vue_tel_input_GukcJ5wu6r
]
import {joinURL, encodePath, encodeParam} from 'ufo';
import {createOperationsGenerator} from '#image'

const operationsGenerator = createOperationsGenerator({
    keyMap: {
        format: 'format',
        width: 'width',
        height: 'height',
        size: 'size',
        quality: 'quality'
    },
    joinWith: ',',
    formatter: (key, val) => encodeParam(key) + '-' + encodeParam(val)
});

export const getImage = (src, {modifiers = {}, baseURL} = {}) => {
    if (!baseURL) {
        baseURL = `${useRuntimeConfig().public.frontUrl}/NIO`;
    }

    if (!src.startsWith("http")) {
        src = useRuntimeConfig().public.frontUrl + src;
    }

    if (modifiers.width && modifiers.height) {
        modifiers.size = `${modifiers.width}_${modifiers.height}`;
        delete modifiers.width;
        delete modifiers.height;
    }

    const params = operationsGenerator(modifiers) || '_';

    return {
        url: joinURL(baseURL, params, encodePath(src))
    };
}
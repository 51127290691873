import { defineRule, configure } from 'vee-validate';
import {required, email, min, max, is, digits, alpha, confirmed, numeric, is_not, between, regex, length} from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import moment from "moment";

export default defineNuxtPlugin(() => {
    configure({
        // Generates an English message locale generator
        generateMessage: localize('en', {
            messages: {
                required: 'Champ requis',
                email: 'Veuillez saisir une adresse e-mail valide',
                emails: 'Veuillez saisir des adresses e-mail valides',
                confirmed: 'Les champs ne correspondent pas',
                length: 'Le champ doit contenir {params} caractères',
                lengthOrNull: 'Le champ doit contenir {params} caractères',
                max: `Le champ ne doit pas contenir plus de {params} caratères`,
                min: `Le champ doit contenir au moins {params} caratères`,
                oneUppercase: 'Le champ doit contenir une majuscule',
                oneLowercase: 'Le champ doit contenir une minuscule',
                oneNumber: 'Le champ doit contenir un chiffre',
                noNumber: 'Le champ ne doit pas contenir de chiffre',
                is: `La valeur de ce champ doit être égale à celle de 0:{other}`,
                between: `Le champ doit être entre 0:{min} et 1:{max}`,
                is_not: `La valeur de ce champ doit être différente à celle déja enregistrée`,
                isValid: `Champ invalide`,
                digits: `Le champ doit contenir exactement 0:{length} chiffres`,
                numeric: `Le champ doit contenir uniquement des chiffres`,
                float: 'Le champ doit contenir uniquement des chiffres',
                regex: `Le champ contient une valeur incorrecte`,
                alpha: `Le champ doit contenir uniquement des lettres`,
                alphaTiret: `Le champ ne peut pas contenir de chiffres`,
                telephoneFixe: `Veuillez entrer un numéro de téléphone fixe valide`,
                isMajeur: `Le réservataire doit être majeur`,
                isAlive: `Le réservataire doit avoir moins de 100 ans`,
                isFuture: `La date est déjà passée`,
                isPositifOuNul: `La valeur doit être positive`,
                isOver: `La valeur de ce champ doit être supérieure au champ "{targetName}"`,
                isOverImpots: `La valeur de ce champ doit être supérieure au champ "Réduction d'impôt"`,
                isLower: `La valeur de ce champ doit être inférieure au champ "{targetName}"`,
                isLowerImpots: `La valeur de ce champ doit être inférieure au champ "Impôts"`,
                isInPercentLimit: `La valeur ne peut pas dépasser 100 %`,
                codePostal: 'Veuillez entrer un code postal valide',
                numberIsUpperOrEqual: `Le champ doit être supérieur ou égal à {targetValue}`,
                numberIsLowerOrEqual: `Le champ doit être inférieur ou égal à {targetValue}`,
                ageIsBetween: `Votre âge doit être compris entre {targetMin} et {targetMax} ans`,
                dateIsValid: 'Veuillez entrer une date valide',
                sumIsLower: 'La somme des champs "{field}" et "{addedField}" doit être inférieure ou égale à {limit}',
                isTotalRepartition: 'La répartition doit être égale à 100 %',
                requiredWithFalse: 'Champ requis'
            },
        }),
    });

    defineRule('required', required);
    defineRule('email', email);
    defineRule('emails', (value, {splitTarget = ','}) => {
        if (!value || value?.length === 0) return false;

        const list = value.split(splitTarget)
        for (const i in list) {
            if(!email(list[i])) {
                return false
            }
        }

        return true
    });

    defineRule('length', length);
    defineRule('lengthOrNull', (value, length) => {
        return value == null || value.length === parseInt(length[0]) || value.length === 0;
    });

    defineRule('min', min);
    defineRule('max', max);
    defineRule('is', is);
    defineRule('is_not', is_not);
    defineRule('between', between);
    defineRule('numeric', numeric);
    defineRule('digits', digits);
    defineRule('alpha', alpha);
    defineRule('regex', regex);
    defineRule('confirmed', confirmed);
    defineRule("alphaTiret", value => {
        if (!value || !value.length) {
            return true;
        }

        return value.match(/^[A-Za-zÀ-ÖØ-öø-ÿ\-. '’ʼʹˈ]+$/g) !== null;
    });
    defineRule('float', value => {
        const regex = new RegExp(`^-?\\d+[.,]?\\d*$`);

        return Array.isArray(value) ? value.every(val => regex.test(String(val))) : regex.test(String(value));
    });
    defineRule("telephoneFixe", value => value.toString().substring(0, 4)[0] === '0' ? value.replaceAll(' ', '').substring(0, 4).match(/(0?[1-58-9][0-9]{2})/g) !== null : value.replaceAll(' ', '').replaceAll('+','').substring(0, 3).match(/([1-58-9][0-9]{2})/g) !== null)
    defineRule("isMajeur", value => moment().diff(value, 'y') >= 18);
    defineRule("isAlive", value => moment().diff(value, 'y') < 100);
    defineRule("isFuture", value => {
        let date = moment(value, value.includes("-") ? 'YYYY-MM-DD' : 'DD/MM/YYYY', true);
        return moment().diff(date, 'd') <= 0;
    });
    defineRule("oneUppercase", value => value.match(/[A-Z]/g) !== null);
    defineRule("oneLowercase", value => value.match(/[a-z]/g) !== null);
    defineRule("oneNumber", value => value.match(/[0-9]/g) !== null);
    defineRule("noNumber", value => value.match(/[0-9]/g) === null);
    defineRule("isPositifOuNul", (value) => {
        if (value === null || value === undefined || value === '') {
            return false;
        }

        return parseFloat(value.toString().replaceAll(/\s+/g,'')) >= 0
    })
    defineRule("codePostal", value => value.replace(/\s/g, '').length === 5);
    defineRule("isInPercentLimit", value => value >= 0 && value <= 100);
    defineRule("dateIsValid", value => moment(value, 'DD/MM/YYYY', true).isValid());
    defineRule("oneOfSelected", (value, values) => values.some(a => JSON.stringify(a) === JSON.stringify(value)));
    defineRule("isOver", (value, {targetValue, targetName}) => {
        if (targetValue !== null) {
            targetValue = targetValue.toString();

            return parseFloat(targetValue.replaceAll(/\s+/g, '')) <= parseFloat(value.replaceAll(/\s+/g, ''));
        } else {
            return true;
        }
    });
    defineRule("isOverImpots", (value, [targetValue]) => {
        if (targetValue) {
            return parseFloat(value.toString().replaceAll(/\s+/g, '')) >= parseFloat(targetValue.toString().replaceAll(/\s+/g, ''));
        } else {
            return true;
        }
    });
    defineRule("isLower", (value, {targetValue, targetName}) => {
        if (targetValue !== null) {
            targetValue = targetValue.toString();
            return parseFloat(targetValue.replaceAll(/\s+/g, '')) >= parseFloat(value.replaceAll(/\s+/g, ''));
        } else {
            return false;
        }
    });
    defineRule("isLowerImpots", (value, [targetValue] ) => {
        if (targetValue) {
            return parseFloat(value.toString().replaceAll(/\s+/g, '')) <= parseFloat(targetValue.toString().replaceAll(/\s+/g, ''));
        } else {
            return false;
        }
    });
    defineRule("sumIsLower", (value, {field, addedField, limit}) => {
        let allValues = [value, field].map(v => {
            v = typeof v === ("undefined") || v === "" || v == null ? "0" : v.toString();
            return parseFloat(v.replaceAll(/\s+/g, ''));
        });

        return allValues.reduce((a, b) => a + b, 0) <= limit;
    });
    defineRule("numberIsUpperOrEqual", (value, {targetValue}) => {
        value = value.toString();
        return parseFloat(value) >= targetValue;
    });
    defineRule("numberIsLowerOrEqual", (value, {targetValue}) => {
        value = value.toString();
        return parseFloat(value) <= targetValue;
    });
    defineRule('ageIsBetween', (value, {targetMin, targetMax}) => {
        value = value.toString();
        return parseFloat(value) >= targetMin && parseFloat(value) <= targetMax;
    });
    defineRule("repassword", (value, [target]) => {
        return value === target;
    });
    defineRule('isTotalRepartition', (value) => {
        return value == 100;
    });
    defineRule('requiredWithFalse', (value) => {
        return !!(value || (typeof value === "boolean" && value === false));
    });
});

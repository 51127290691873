<template>
    <component
        :is="getComponent()"
        v-bind="getProps()"
        :class="[date ? 'pb-2' : 'pb-4']"
        class="flex flex-col bg-grey200 w-[15rem] h-full rounded-xl cursor-pointer hover:text-tangerine hover:brightness-95 transition-all"
    >
        <div class="w-full">
            <nuxt-img
                v-if="image"
                :src="srcImage" :alt="alt"
                class="mx-auto rounded-t-xl object-cover"
                :style="{height: imageHeight}"
                quality="80"
                width="300"
                :class="[isFullSize ? 'w-[15rem]' : 'w-[14rem] mt-3']"
            />
        </div>

        <div class="mx-4 flex flex-col h-full gap-y-2 mt-4">
            <div class="text-grey7 text-sm">
                <span>{{title.toUpperCase()}}</span>
            </div>

            <div class="text-black text-sm font-medium">
                <div class="whitespace-normal">
                    {{subTitle}}
                </div>
            </div>

            <div v-if="date" class="text-grey7">{{date}}</div>
        </div>
    </component>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    subTitle: {
        type: String,
        default: ''
    },
    date: {
        type: String,
        default: ''
    },
    image: {
        type: String,
        default: ''
    },
    to: {
        type: [String, Object],
        default: ''
    },
    alt: {
        type: String,
        default: 'alt image'
    },
    config: {
        type: Object,
        default: {}
    }
})

const image = toRef(props, 'image');
const config = toRef(props, 'config');
const runtimeConfig = useRuntimeConfig().public;

const isFullSize = ref(true);
const imageHeight = ref('8.2rem');

const srcImage = computed(() => {
    if (image.value !== null && image.value !== '') {
        if (image.value.startsWith('menu')) {
            const encoded = btoa(image.value.replaceAll('\\', '/'));
            return `${runtimeConfig.baseUrl}/config/image/${encoded}`;
        } else {
            return 'images/menu/'.concat(image.value);
        }
    } else {
        return '/images/default/menu.webp';
    }
})

function getComponent() {
    return (props.to != null ? (isHttpRedirection() ? 'a' : 'RouterLink') : 'div')
}

function isHttpRedirection() {
    return typeof props.to === 'string' && props.to.startsWith('http')
}

function getProps() {
    if (isHttpRedirection()) {
        return {
            href: props.to,
            target: '_blank'
        }
    } else {
        return {
            to: props.to
        }
    }
}

watch(config, () => {
    if ('fullSize' in config.value) {
        isFullSize.value = config.value.fullSize;
    }

    if ('imageHeight' in config.value) {
        imageHeight.value = config.value.imageHeight;
    }
}, {immediate:true, deep:true})
</script>

<style scoped lang="scss">

</style>

<template>
    <component :is="(to == null || disabled) ? 'button' : 'RouterLink'" class="rounded font-medium w-fit" :class="classes"
               @mousedown.middle="onMiddleClick($event)"
               @click="(disabled || loading) ? clickPrevent($event) : onClick($event)"
               @mouseenter="mouseenter($event)"
               @mouseenter.native="mouseenter($event)"
               @mouseleave="mouseleave($event)"
               @mouseleave.native="mouseleave($event)"
               :to="disabled ? null : to">
        <slot v-if="!loading"></slot>
        <c-loader v-else :color="loaderColor"></c-loader>
    </component>
</template>

<script>
export default {
    name: "CButton",
    data: () => ({
        hovered: false,
    }),
    emits: ['click', 'mouseleave', 'mouseenter', 'clickDisabled'],
    props: {
        large: {
            type: Boolean,
            required: false,
            default: false
        },
        href: {
            type: String,
            required: false,
            default: null
        },
        to: {
            type: [Object, String, null],
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        greyDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        loaderColor: {
            type: String,
            required: false,
            default: "white"
        }
    },
    methods: {
        onClick(event) {
            if (this.disabled || this.loading) {
                this.$emit('clickDisabled', event);
                return;
            }

            this.$emit('click', event);

            if (this.href != null) {
                if (this.href.startsWith("http")) {
                    window.location.href = this.href;
                } else {
                    useRouter().push({path: this.href});
                }
            }
        },
        onMiddleClick(event) {
            if (this.disabled || this.loading) {
                this.$emit('clickDisabled', event);
                return;
            }

            this.$emit('click', event);

            if (this.href != null) {
                if (this.href.startsWith("http")) {
                    window.open(this.href, '_blank');
                } else {
                    useRouter().push({path: this.href});
                }
            }
        },
        mouseenter(event) {
            this.$emit('mouseenter', event);

            this.hovered = true;
        },
        mouseleave(event) {
            this.$emit('mouseleave', event);

            this.hovered = false;
        },
        clickPrevent(e){
            this.$emit('clickDisabled', e);
            e.preventDefault()
        }
    },
    computed: {
        classes() {
            let classes = [];

            classes.push(this.large ? 'py-5' : (this.loading ? 'py-[0.85rem]' : 'py-4'));
            classes.push(this.large ? 'px-6' : 'px-4');
            classes.push(this.large ? 'text-base' : 'text-sm');

            if (this.disabled === true) {
                classes.push("opacity-50");
                classes.push("cursor-default");

                if (this.greyDisabled === true) {
                    classes.push("!opacity-30");
                    classes.push("grayscale");
                }
            }

            if (this.disabled === false && this.hovered === true) {
                classes.push("brightness-95");
            }

            return classes;
        }
    }
}
</script>

<style scoped lang="scss">

</style>

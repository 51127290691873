import {io} from "socket.io-client";

export default defineNuxtPlugin((nuxtApp) => {
    let socket = io(nuxtApp.$config.public.chatUrl, { autoConnect: false, transports: ['websocket', 'polling', 'flashsocket'] });

    socket.onAny((event, ...args) => {
        //console.log(event, args);
    });

    return {
        provide: {
            socket
        },
    };
});

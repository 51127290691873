<template>
    <svg viewBox="0 0 29 29" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M28.8933 20.4749C28.775 22.7999 28.2486 24.8527 26.5511 26.5506C24.8536 28.2544 22.8011 28.7809 20.4766 28.8933C18.0812 29.0294 10.9066 29.0294 8.51703 28.8933C6.19253 28.775 4.14603 28.2484 2.44258 26.5506C0.739136 24.8527 0.212723 22.7999 0.100343 20.4749C-0.0356959 18.0848 -0.0356959 10.9088 0.100343 8.51283C0.218638 6.18786 0.739136 4.13502 2.44258 2.43714C4.14603 0.739264 6.20436 0.212744 8.51703 0.100341C10.9125 -0.0357257 18.0871 -0.0357257 20.4826 0.100341C22.8071 0.21866 24.8595 0.74518 26.557 2.44306C28.2605 4.14094 28.7869 6.19377 28.8992 8.51874C29.0353 10.9147 29.0353 18.0848 28.8992 20.4749H28.8933ZM26.3855 14.4939C26.3855 12.3819 26.5629 7.85616 25.8058 5.9453C25.2972 4.67337 24.3153 3.69132 23.0496 3.18847C21.145 2.43714 16.6143 2.60871 14.5028 2.60871C12.3912 2.60871 7.86641 2.43123 5.95594 3.18847C4.68427 3.69724 3.70242 4.67929 3.19967 5.9453C2.4485 7.85024 2.62003 12.3819 2.62003 14.4939C2.62003 16.6059 2.44258 21.1316 3.19967 23.0424C3.70834 24.3143 4.69019 25.2964 5.95594 25.7992C7.86049 26.5506 12.3912 26.379 14.5028 26.379C16.6143 26.379 21.1391 26.5565 23.0496 25.7992C24.3212 25.2905 25.3031 24.3084 25.8058 23.0424C26.5629 21.1375 26.3855 16.6059 26.3855 14.4939ZM21.9376 14.4939C21.9376 18.6114 18.6194 21.9302 14.5028 21.9302C10.3861 21.9302 7.06791 18.6114 7.06791 14.4939C7.06791 10.3764 10.3861 7.0575 14.5028 7.0575C18.6194 7.0575 21.9376 10.3764 21.9376 14.4939ZM19.3351 14.4939C19.3351 11.8258 17.1703 9.66052 14.5028 9.66052C11.8352 9.66052 9.6704 11.8258 9.6704 14.4939C9.6704 17.1619 11.8411 19.3272 14.5028 19.3272C17.1644 19.3272 19.3351 17.1619 19.3351 14.4939ZM22.2392 8.48916C21.2751 8.48916 20.5062 7.71417 20.5062 6.75579C20.5062 5.7974 21.281 5.02241 22.2392 5.02241C23.1974 5.02241 23.9723 5.7974 23.9723 6.75579C23.9723 7.71417 23.1974 8.48916 22.2392 8.48916Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "Instagram"
}
</script>

<style scoped>

</style>

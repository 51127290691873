import {Buffer} from 'buffer/';
import {useUserStore} from "~/store/user";
import {getUserAgent} from "~/composables/urlMixin";
import useGtmTrackEvent from "~/composables/gtmTracking";

export default defineNuxtRouteMiddleware((to, from) => {
    const {$axios} = useNuxtApp();

    if (process.server) {
        return;
    }

    // si on est sur le sous-domaine valority
    if (location.host.includes('capacite-emprunt')) {
        return;
    }

    const userStore = useUserStore();
    let path = to.path.split('/');
    path.shift();

    useGtmTrackEvent({
        event: 'datalayer_ready',
        content_group: path[0] ?? '', // Section du menu principal
        content_group2: path[1] ?? '', // Sous-section, thématique de la page,
        content_group3: path[2] ?? '',
        user_id: userStore.id
    });

    let slug = to.fullPath.split("?")[0];

    if (slug.includes('#')) {
        slug = slug.split('#')[0];
    }

    const parameters = to.query;
    let utmParameters = {};
    let gclidParameters = [];
    let otherParameters = {};

    // separating UTM and GCLID parameters from other unrelated GET parameters
    for (let property in parameters) {
        if (property.startsWith("utm")) {
            utmParameters[property] = parameters[property];
        } else if (property.startsWith("gclid")) {
            if (Array.isArray(parameters[property])) {
                for (let gclid of parameters[property]) {
                    gclidParameters.push(gclid);
                }
            } else {
                gclidParameters.push(parameters[property]);
            }
        }/* else {
            otherParameters[property] = parameters[property];
        }*/

        //désactivé pour le moment, Google a pas le temps de les chopper, on est trop rapide
        otherParameters[property] = parameters[property];
    }

    if (Object.keys(utmParameters).length > 0 || gclidParameters.length > 0) {
        localStorage.setItem('redirected', 'false');
    }

    let callback = {
        func: (slug, utmParameters, gclidParameters, otherParameters, $axios) => {
            return $axios.post(
                '/log/save',
                {
                    'slug': Buffer.from(slug).toString('base64'),
                    'utmParameters': utmParameters,
                    'gclidParameters': gclidParameters,
                    'otherParameters': otherParameters,
                    'userAgent': getUserAgent(navigator.userAgent),
                },
                {headers: {'X-USER-TOKEN': localStorage.getItem("userToken")}}
            ).catch((e) => {
                console.error(e);
            });
        },
        args: [slug, utmParameters, gclidParameters, otherParameters, $axios]
    }

    // posting a new activity log ONLY if we're not being redirected to this code after cleaning the URL
    if (localStorage.getItem("userToken") != null && userStore.impersonate === false) {
        callback.func(...callback.args);
    } else {
        userStore.getOrFetchToken(userStore.impersonate === true ? null : callback);
    }
    // redirecting the user with a clean URL after putting back other unrelated GET parameters
    //désactivé pour le moment, Google a pas le temps de les chopper, on est trop rapide
    /* if (Object.keys(utmParameters).length > 0 || gclidParameters.length > 0) {
         localStorage.setItem('redirected', 'true');

         const query = {...otherParameters};

         app.router.push({path: slug, query: query});
     } else {
         localStorage.setItem('redirected', 'false');
     }*/
});

<template>
    <svg viewBox="0 0 16 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.75 7.63281V4.57422C15.7148 4.57422 15.7148 4.57422 15.7148 4.57422C14.8711 4.57422 14.0625 4.32812 13.3945 3.87109C12.4102 3.23828 11.707 2.21875 11.4961 1.05859C11.4258 0.8125 11.4258 0.566406 11.4258 0.320312C11.4258 0.285156 11.4258 0.285156 11.4258 0.25H8.33203V12.5547C8.33203 13.9961 7.13672 15.1914 5.69531 15.1914C4.25391 15.1914 3.05859 13.9961 3.05859 12.5547C3.05859 11.1133 4.25391 9.91797 5.69531 9.91797C5.97656 9.91797 6.22266 9.95312 6.50391 10.0586V6.89453C6.22266 6.85938 5.97656 6.82422 5.69531 6.82422C2.53125 6.82422 0 9.39062 0 12.5547C0 15.7188 2.53125 18.25 5.69531 18.25C8.85938 18.25 11.4258 15.7188 11.4258 12.5547V6.26172C12.6211 7.14062 14.0977 7.63281 15.7148 7.63281H15.75Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "TikTok"
}
</script>

<style scoped>

</style>

<template>
    <svg viewBox="0 0 710 194" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M149.37 7.1V138.51H117.37V59.71L88 138.51H62.13L32.56 59.51V138.51H0.549988V7.1H38.36L75.24 98.1L111.74 7.1H149.37Z" fill="currentColor"/>
        <path d="M149.37 193.15H0.549988V161.15H149.37V193.15Z" fill="currentColor"/>
        <path d="M167.82 57.84C171.481 50.1711 177.222 43.6847 184.39 39.12C191.404 34.7469 199.525 32.474 207.79 32.57C214.521 32.4224 221.181 33.9662 227.16 37.06C232.418 39.7749 236.852 43.8497 240 48.86V34.07H272V138.52H240V123.74C236.749 128.72 232.272 132.78 227 135.53C221.01 138.622 214.339 140.159 207.6 140C199.384 140.081 191.321 137.774 184.39 133.36C177.215 128.721 171.476 122.172 167.82 114.45C163.76 106.283 161.733 96.83 161.74 86.09C161.747 75.35 163.773 65.9333 167.82 57.84ZM233.34 67.39C231.249 65.2146 228.741 63.4822 225.966 62.2961C223.192 61.11 220.207 60.4945 217.189 60.4861C214.171 60.4777 211.183 61.0766 208.402 62.2472C205.621 63.4179 203.103 65.1362 201 67.3C196.573 71.8533 194.36 78.1233 194.36 86.11C194.36 94.0967 196.573 100.43 201 105.11C203.077 107.319 205.583 109.08 208.364 110.287C211.145 111.494 214.144 112.121 217.176 112.129C220.207 112.138 223.209 111.528 225.997 110.336C228.785 109.145 231.301 107.397 233.39 105.2C237.797 100.593 240 94.2933 240 86.3C240 78.3067 237.78 72.0033 233.34 67.39Z" fill="currentColor"/>
        <path d="M309.62 135.34C302.99 132.595 297.184 128.178 292.77 122.52C288.711 117.253 286.3 110.904 285.84 104.27H317.48C317.633 106.01 318.159 107.696 319.022 109.214C319.885 110.733 321.064 112.048 322.48 113.07C325.692 115.377 329.577 116.559 333.53 116.43C337.53 116.43 340.61 115.66 342.79 114.09C343.806 113.436 344.639 112.536 345.213 111.472C345.787 110.409 346.081 109.218 346.07 108.01C346.07 105.01 344.51 102.8 341.39 101.37C336.48 99.3535 331.409 97.7545 326.23 96.59C319.869 95.1868 313.614 93.3424 307.51 91.07C302.508 89.1348 298.067 85.9831 294.59 81.9C290.97 77.72 289.16 72.0733 289.16 64.96C289.124 59.1188 290.868 53.4053 294.16 48.58C297.493 43.6533 302.36 39.7533 308.76 36.88C315.16 34.0067 322.803 32.57 331.69 32.57C344.803 32.57 355.137 35.8167 362.69 42.31C370.243 48.8033 374.577 57.4133 375.69 68.14H346.12C345.936 66.4592 345.418 64.832 344.597 63.3536C343.776 61.8753 342.669 60.5754 341.34 59.53C338.673 57.41 335.15 56.35 330.77 56.35C327.02 56.35 324.15 57.06 322.16 58.5C321.204 59.1496 320.428 60.0302 319.904 61.0598C319.379 62.0895 319.124 63.235 319.16 64.39C319.16 67.39 320.753 69.6367 323.94 71.13C327.127 72.6233 332.087 74.1233 338.82 75.63C345.234 77.207 351.546 79.174 357.72 81.52C362.699 83.5415 367.105 86.7558 370.55 90.88C374.23 95.1933 376.133 100.967 376.26 108.2C376.334 114.07 374.53 119.809 371.11 124.58C367.327 129.654 362.206 133.574 356.32 135.9C349.234 138.763 341.641 140.157 334 140C325.637 140.163 317.333 138.576 309.62 135.34Z" fill="currentColor"/>
        <path d="M424.18 0V138.52H392.18V0H424.18Z" fill="currentColor"/>
        <path d="M463.58 133.47C455.502 129.193 448.813 122.698 444.3 114.75C439.633 106.643 437.3 97.16 437.3 86.3C437.3 75.56 439.67 66.1067 444.41 57.94C448.975 49.9402 455.73 43.4108 463.88 39.12C472.48 34.8129 481.966 32.5702 491.585 32.5702C501.204 32.5702 510.69 34.8129 519.29 39.12C527.444 43.4053 534.2 49.9361 538.76 57.94C543.5 66.1067 545.87 75.56 545.87 86.3C545.87 97.04 543.47 106.493 538.67 114.66C534.029 122.668 527.207 129.192 519 133.47C510.413 137.907 500.864 140.157 491.2 140.02C481.591 140.174 472.096 137.922 463.58 133.47ZM506.82 105.58C511.127 101.08 513.28 94.6533 513.28 86.3C513.28 77.9467 511.187 71.5133 507 67C505.017 64.8783 502.621 63.185 499.959 62.0242C497.297 60.8634 494.426 60.2597 491.522 60.2504C488.618 60.241 485.742 60.8261 483.073 61.9697C480.404 63.1133 477.997 64.7912 476 66.9C471.88 71.3267 469.82 77.7867 469.82 86.28C469.82 94.64 471.85 101.067 475.91 105.56C477.804 107.731 480.151 109.459 482.786 110.624C485.421 111.788 488.28 112.361 491.16 112.3C494.096 112.346 497.009 111.771 499.708 110.613C502.407 109.455 504.83 107.74 506.82 105.58Z" fill="currentColor"/>
        <path d="M709.09 34.07L680.82 138.52H645.44L629 70.76L612 138.52H576.74L548.29 34.07H580.29L595.07 108.76L612.67 34.07H646.55L664.34 108.38L679 34.07H709.09Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "Full"
}
</script>

<style scoped>

</style>

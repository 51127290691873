import { defineStore } from 'pinia';
import {getLocalStorageValue, setLocalStorageValue} from "~/composables/storage";
import {useReservationStore} from "~/store/reservation";
import {useUserStore} from "~/store/user";


export const useVersionStore = defineStore({
    id: 'version-store',
    state: () => ({
        clientVersion: -1,
        ABVersion: null,
        appVersion: null,
        currentVersionRepartition: {
            'V1-AB': {
                pageBien: {
                    'b': 50,
                    'a': -1
                }
            }
        },
        currentVersion: {}
    }),
    actions: {
        async initVersion(token=null) {
            await this.fetchVersion(token);

            if (this.versionNeedUpdate()) {
                this.currentVersion = await this.generateAppVersionTable();
                setLocalStorageValue('appVersionTable', JSON.stringify(this.currentVersion));
                setLocalStorageValue('ABVersion', Object.keys(this.currentVersionRepartition).join('_'));
                setLocalStorageValue('clientVersion', this.clientVersion);
            } else {
                this.currentVersion = this.getAppVersionTable();
            }
        },
        async generateAppVersionTable() {
            let appVersionTable = {};

            Object.entries(this.currentVersionRepartition).forEach((versionAB) => {
                Object.entries(versionAB[1]).forEach((section) => {
                    for (let versionSection of Object.entries(section[1])) {
                        if (this.currentVersionRepartition[versionAB[0]][section[0]][versionSection[0]] < this.clientVersion)
                        {
                            if (!appVersionTable.hasOwnProperty(versionAB[0])) {
                                appVersionTable[versionAB[0]] = {};
                            }

                            appVersionTable[versionAB[0]][section[0]] = versionSection[0];
                            break;
                        }
                    }
                })
            })

            return appVersionTable;
        },

        versionNeedUpdate() {
            return getLocalStorageValue('ABVersion') !== Object.keys(this.currentVersionRepartition).join('_') || parseInt(getLocalStorageValue('clientVersion')) !== this.clientVersion;
        },
        fetchVersion(token = null) {
            const url = ('/user/version/').concat(token ?? getLocalStorageValue('userToken'));

            return useNuxtApp().$axios.get(url).then((r) => {
                if (r && r.data && Number.isInteger(r.data)) {
                    this.clientVersion = r.data;
                }
            }).catch((e) => {
                console.error(e);

                if (e.response.status === 400) {
                    localStorage.removeItem('userToken');
                    location.reload();
                }
            });
        },
        getVersionForSection(version, section) {
            const tabAppVersion = this.getAppVersionTable();

            if (tabAppVersion !== null && tabAppVersion.hasOwnProperty(version) && tabAppVersion[version].hasOwnProperty(section)) {
                return tabAppVersion[version][section];
            } else {
                return 'a';
            }
        },
        getAppVersionTable() {
            const appVersionTable = getLocalStorageValue('appVersionTable');

            if (appVersionTable == null) {
                return null;
            } else {
                return JSON.parse(appVersionTable);
            }
        },
        getAllVersionForSection(version, section) {
            return Object.keys(this.currentVersionRepartition[version][section]);
        }
    }
});

export default {
  default: () => import("/app/layouts/default.vue").then(m => m.default || m),
  "default-background-grey": () => import("/app/layouts/defaultBackgroundGrey.vue").then(m => m.default || m),
  "default-chat-up": () => import("/app/layouts/defaultChatUp.vue").then(m => m.default || m),
  "default-chat-up-mobile": () => import("/app/layouts/defaultChatUpMobile.vue").then(m => m.default || m),
  "default-chat-up-mobile-without-footer": () => import("/app/layouts/defaultChatUpMobileWithoutFooter.vue").then(m => m.default || m),
  "default-without-footer": () => import("/app/layouts/defaultWithoutFooter.vue").then(m => m.default || m),
  empty: () => import("/app/layouts/empty.vue").then(m => m.default || m),
  "reservation-form": () => import("/app/layouts/reservationForm.vue").then(m => m.default || m),
  simulation: () => import("/app/layouts/simulation.vue").then(m => m.default || m),
  valority: () => import("/app/layouts/valority.vue").then(m => m.default || m)
}
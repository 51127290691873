<template>
    <div class="pb-0">
        <c-tooltip :background-color="backgroundColor" :color="color" :position="position">
            <template v-slot:activator="{on}">
                <div ref="slotValue" v-on="on" class="pt-0 mt-[-20%] text-white underline underline-offset-2 text-lg cursor-pointer">
                    <slot></slot>
                </div>
            </template>

            <div class="w-80 px-4 pt-2 pb-0 whitespace-pre-line rounded-lg">
                <div class="w-full flex flex-row items-center">
                    <div class="rounded-full w-14 h-14 overflow-hidden flex items-center justify-center">
                        <nuxt-img
                            format="webp"
                            class="w-full block"
                            densities="1x"
                            :src=photo
                            alt="Votre expert">
                        </nuxt-img>
                    </div>

                    <div class="pl-4 leading-6 text-base">
                        <div>Votre expert</div>
                        <div class="text-tangerine">{{conseillerInfo.prenom}} {{conseillerInfo.nom}}</div>
                    </div>
                </div>

                <div>
                    <div class="pb-3 font-normal leading-5 text-sm">
                        {{conseillerInfo.description}}
                    </div>
                </div>
            </div>
        </c-tooltip>
    </div>
</template>

<script>
export default {
    name: "previsualisationConseillerChat",
    props:{
        conseillerInfo:{
            type: Object,
            require: false,
        },
        photo:{
            type: String,
            require: false,
        },
        position:{
            type: String,
            require: false,
            default: "top"
        },
        backgroundColor: {
            type: String,
            default: "#004D43"
        },
        color: {
            type: String,
            default: "white"
        }
    }
}

</script>

<style scoped>

</style>

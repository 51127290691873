/** Wrapper pour GTM qui fait chier tout le monde sinon, lui passer l'àbojet à push en param et on en parle plus */
export default function useGtmTrackEvent(event) {
    const env = process.env.NODE_ENV;

    try {
        if (process.client && (Cookiebot?.consent.statistics || env === 'development')) {
            useGtm()?.trackEvent(event);

            if (env === 'development') {
                console.log('Custom gtmTrackEvent: ', event)
            }
        }
    } catch (e) {
        console.error('Custom gtmTrackEvent error:', e);
    }
}

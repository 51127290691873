<template>
    <transition name="chat-opener" appear>
        <div class="h-[32.25rem] w-[23.43rem] md:w-[30rem] bg-white rounded-xl fixed bottom-[7rem] right-8 shadow-xl dialog flex flex-col
            sm:h-full sm:w-full sm:top-0 sm:bottom-0 sm:left-0 sm:right-0 sm:min-h-full sm:rounded-none z-50"
             :class="{'closed': closed}" v-if="!closed"
        >
            <div class="bg-tangerine min-h-[4.68rem] w-full rounded-t-xl py-[0.625rem] px-4 flex flex-row sm:rounded-none">
                <div class="rounded-full bg-white/[0.3] flex w-14 h-14 items-center justify-center align-middle relative">
                    <div class="rounded-full w-full h-full overflow-hidden flex items-center justify-center">
                        <img v-if="interlocuteurData != null" :src="interlocuteurData.photo" class="w-14 block" alt="photo interlocuteur">

                        <img v-else src="/images/chat/maslow-logo.svg" class="w-6 h-8 block" alt="logo maslow">
                    </div>

                    <c-status-indicator white class="absolute right-0 top-0" :type="error ? 'error' : isChatOnline ? 'active' : 'away'"></c-status-indicator>
                </div>

                <div class="flex flex-col text-base font-normal pl-3 text-white pt-1">
                    <div class="font-semibold">
                        <div v-if="interlocuteurData == null">Chat Maslow</div>

                        <div class="flex items-center" v-else>
                            <div>{{ interlocuteurData.info.prenom }} {{ interlocuteurData.info.nom }}</div>

                            <div class="ml-2" v-if="interlocuteurData.linkedin">
                                <a :href="interlocuteurData.linkedin" target="_blank"><img alt="logo linkedin" src="/images/chat/linkedin.svg"></a>
                            </div>
                        </div>
                    </div>

                    <div v-if="isChatOnline">
                        <div v-if="interlocuteurData == null">
                            Dites-nous tout...
                        </div>

                        <div v-else>
                            <PrevisualisationConseillerChat :conseiller-info="interlocuteurData.info" :photo="interlocuteurData.photo">
                                <div class="underline">Voir sa bio</div>
                            </PrevisualisationConseillerChat>
                        </div>
                    </div>

                    <div v-else>
                        Nos conseillers sont absents...
                    </div>

                    <div class="absolute right-3 top-3 hidden sm:block" @click="$emit('close')">
                        <icons-x-solid class="w-8 h-8"></icons-x-solid>
                    </div>
                </div>
            </div>

            <div class="flex h-full flex-col pb-3 relative max-h-[87%] sm:max-h-[92%]">
                <div v-if="loading" class="absolute top-0 left-0 right-0 bottom-0 bg-black/[.5] z-10 pt-20 rounded-b-xl">
                    <c-loader class="absolute" color="white" width="5vw"></c-loader>
                </div>

                <div class="w-full flex-grow overflow-y-auto scroll-light" ref="messages">
                    <chat-messages
                        v-if="configHolidays !== null"
                        :closed="closed"
                        :error="error"
                        :conversation="conversation"
                        :is-chat-online="isChatOnline"
                        :config-holidays="configHolidays"
                        @interlocuteur="lastInterlocuteurUpdate">
                    </chat-messages>
                </div>

                <div class="w-full flex-initial h-fit flex flex-row mb-1" v-show="!error">
                    <chat-text-input
                        class="mx-4"
                        @message="onMessageSubmit"
                        :files="files"
                        @removedoc="removeFile"
                        :oversized="areAttachementsOversized">
                    </chat-text-input>
                </div>

                <div class="flex w-full items-center justify-center text-xs mb-3 cursor-pointer" @click="selectFile" v-show="!error">
                    <input ref='fileupload' accept="image/*,.pdf" type='file' hidden @change="fileSelectChange" multiple/>

                    <icons-link-solid class="w-4 h-4 mr-1"></icons-link-solid> envoyer un fichier (image, pdf)
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import moment from 'moment';
import PrevisualisationConseillerChat from "~/components/chat/PrevisualisationConseillerChat";
import useGtmTrackEvent from "~/composables/gtmTracking";

const uploadMaxSize = 15; //15mo
export default {
    name: "Dialogue",
    components: {PrevisualisationConseillerChat},
    data: () => ({
        loading: true,
        conversations: {},
        files: [],
        interlocuteurData: null
    }),
    props: {
        closed: {
            type: Boolean,
            required: true
        },
        isChatOnline: {
            type: Boolean,
            required: true
        },
        error: {
            type: Boolean,
            required: true
        },
        configHolidays: {
            type: [Object, null],
            required: true
        }
    },
    watch: {
        isChatOnline: {
            handler() {
                if (this.isChatOnline === false) {
                    this.addOfflineMessage(Object.keys(this.conversations)[0]);
                } else {
                    this.removeOfflineMessage(Object.keys(this.conversations)[0]);
                }
            }
        }
    },
    methods: {
        setConversationMessages(conversationId, messages) {
            if (this.conversations[conversationId] != null) {
                this.conversations[conversationId].messages = messages;
            }

            this.$nextTick(() => {
                this.scrollToLastMessage();
            });
        },
        addOfflineMessage(conversationId) {
            if (this.conversations[conversationId] != null) {
                this.conversations[conversationId].messages.push({
                    type: this.configHolidays.state === true ? "holidays" : "offline",
                    sendedAt: moment()
                });
            }
        },
        removeOfflineMessage(conversationId) {
            if (this.conversations[conversationId] != null) {
                this.conversations[conversationId].messages.forEach((message, index) => {
                    if (message.type != null && message.type === (this.configHolidays.state === true ? "holidays" : "offline")) {
                        this.$delete(this.conversations[conversationId].messages, index);
                    }
                });
            }
        },
        onMessageSubmit(message) {
            if (((message != null && message.trim().length > 0) || this.files.length > 0) && !this.areAttachementsOversized) {
                let data = {
                    content: message != null ? message.trim() : '',
                    to: this.conversation.id,
                };

                if (this.files.length > 0) {
                    data.files = this.files;
                }

                useNuxtApp().$socket.emit("conversation message", data);

                useGtmTrackEvent({
                    event: 'chat',
                    chat_interaction: 'message sent'
                });

                this.files = [];
            }
        },
        onMessage(conversationId, message) {
            this.conversations[conversationId].messages.push(message);

            this.$nextTick(() => {
                this.scrollToLastMessage();
            });
        },
        scrollToLastMessage() {
            let container = this.$refs.messages;

            if (this.$refs.messages != null) {
                container.scrollTop = container.scrollHeight;
            }
        },
        fileSelectChange() {
            Array.from(this.$refs.fileupload.files).forEach((file) => {
                if (/(image\/.*)|(application\/pdf)/.test(file.type)) {
                    this.files.push({content: file, filename: file.name});
                }
            });

            this.$refs.fileupload.value = "";
        },
        selectFile() {
            this.$refs.fileupload.click();
        },
        removeFile(index) {
            this.$delete(this.files, index);
        },
        lastInterlocuteurUpdate(interlocuteur) {
            if (interlocuteur == null) {
                this.interlocuteurData = null;

                return;
            }

            useNuxtApp().$axios.get(`/userAdmin/${interlocuteur}`).then(({data}) => {
                this.interlocuteurData = data;
            }).catch((e) => {
                console.error(e);
            });
        }
    },
    computed: {
        conversation() {
            if (this.conversations != null && Object.keys(this.conversations).length > 0) {
                return this.conversations[Object.keys(this.conversations)[0]] ?? null;
            }

            return null;
        },
        areAttachementsOversized() {
            let size = 0;

            this.files.forEach((file) => {
                size += file.content.size;
            });

            return size / 1024 / 1024 > uploadMaxSize;
        }
    },
    mounted() {
        useNuxtApp().$socket.on("conversations", (conversations) => {
            let formatedConversations = {};
            conversations.forEach(conversation => {
                conversation.messages = [];

                formatedConversations[conversation.id] = conversation;
            });

            this.conversations = formatedConversations;

            useNuxtApp().$socket.emit("conversation messages", Object.keys(this.conversations)[0]);
        });

        useNuxtApp().$socket.on('conversation messages', ({conversationId, messages}) => {
            this.setConversationMessages(conversationId, messages);
            this.loading = false;

            if (!this.isChatOnline) {
                this.addOfflineMessage(conversationId);
            }
        });

        useNuxtApp().$socket.on("conversation message", ({ conversationId, message }) => {
            if (conversationId === this.conversation.id) {
                this.onMessage(conversationId, message);

                if (!message.fromMyself) {
                    useNuxtApp().$emit('open-chat')

                    (new Audio("/sounds/chat/notification.mp3")).play();
                }
            }
        });
    },
    beforeDestroy() {
        useNuxtApp().$socket.off("conversations");
        useNuxtApp().$socket.off("conversation messages");
        useNuxtApp().$socket.off("conversation message");
    }
}
</script>

<style scoped lang="scss">
.dialog {
    opacity: 1;
    transition: opacity .3s ease-in-out, max-height .3s ease-in-out;
    max-height: 34.75rem;

    &.closed {
        pointer-events: none;
        opacity: 0;
        max-height: 0;
    }
}

.chat-opener-enter-active {
    transition: all .3s ease;
    opacity: 1;
}

.chat-opener-leave-active {
    transition: all .3s ease-out;
}

.chat-opener-enter, .chat-opener-leave-to {
    transform: translateY(100vh) translateX(100vw);
    width: 0;
    height: 0;
    opacity: 0;
}
</style>

import {defineStore} from 'pinia';
import {getLocalStorageValue} from "~/composables/storage";
import {useVersionStore} from "~/store/version";

export const useUserStore = defineStore({
    id: 'user-store',
    state: () => ({
        isLoggedIn: false,
        email: null,
        id: null,
        nom: null,
        prenom: null,
        token: null,
        telephonePortable: null,
        tutoReaded: false,
        codeParrainageUser: null,
        codeParrainageRegister: null,
        hasRdv: false,
        isSubscribe: false,
        isClient: false,
        hasInShoppingCart: false,
        promoAlreadyUsed: false,
        isFetchingToken: false,
        promiseFetch: null,
        impersonate: false,
        callbacks: []
    }),
    actions: {
        setUser(user) {
            this.id = user.id;
            this.email = user.email;
            this.prenom = user.prenom;
            this.nom = user.nom;
            this.telephonePortable = user.telephonePortable;
            this.adresse = user.adresse;
            this.codePostal = user.codePostal;
            this.tutoReaded = user.tutoReaded;
            this.codeParrainageUser = user.codeParrainageUser;
            this.codeParrainageRegister = user.codeParrainageRegister;
            this.isLoggedIn = true;
            this.hasRdv = user.rdv;
            this.promoAlreadyUsed = user.dateParrainage !== null && user.dateParrainage !== undefined;
            this.impersonate = user.impersonate;
        },
        logout() {
            this.isLoggedIn = false;
            this.email = null;
            this.id = null;
            this.prenom = null;
            this.nom = null;
            this.telephonePortable = null;
            this.adresse = null;
            this.codePostal = null;
            this.tutoReaded = false;
            this.hasRdv = null;
            this.isSubscribe = false;
            this.isClient = false;
            this.hasInShoppingCart = false;
            this.promoAlreadyUsed = false;
            this.isFetchingToken = false;
            this.promiseFetch = null;
            this.impersonate = false;
        },
        login(payload) {
            return useNuxtApp().$axios.post('/connexion', payload).then((r) => {
                this.fetchNewsletter();

                return r;
            });
        },
        create(payload) {
            return useNuxtApp().$axios.post('/inscription/create', payload);
        },
        setRdv(payload) {
            this.hasRdv = payload;
        },
        fetchNewsletter() {
            return useNuxtApp().$axios.get('/user/preferences').then(r => {
                this.isSubscribe = r.data && !r.data.newsletter.emailBlacklisted;
                this.isClient = r.data && r.data.isClient;
                this.hasInShoppingCart = r.data && r.data.hasInShoppingCart;
            });
        },
        async getOrFetchToken(callback = null) {
            if (window?.localStorage?.getItem("userToken") == null && this?.isFetchingToken === false) {
                this.isFetchingToken = true;

                const url = useRuntimeConfig().public.baseUrl + '/user/token';

                await fetch(url , {method: 'GET'})
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Erreur lors de la création du token');
                        } else {
                            return response.json();
                        }
                    })
                    .then(data => {
                        if (data.hasOwnProperty('token')) {
                            this.token = data.token;
                            this.updateStorageUserToken(data.token, false);
                        } else {
                            throw new Error('Erreur lors de la création du token');
                        }
                    })
                    .catch(error => {
                        console.error('Une erreur s\'est produite:', error);
                    })
                    .finally(() => {
                        if (this.callbacks.length > 0) {
                            let promises = [];

                            this.callbacks.forEach(c => promises.push(c.func(...c.args)))

                            Promise.all(promises).finally(() => {
                                this.callbacks = [];
                            });
                        }

                        this.promiseFetch = null;
                        this.isFetchingToken = false;
                });
            } else {
                if (callback) {
                    if (window?.localStorage?.getItem("userToken") == null) {
                        this.callbacks.push(callback);
                    } else {
                        callback.func(...callback.args);
                    }

                    return;
                }

                return window?.localStorage?.getItem('userToken');
            }
        },
        async nuxtClientInit() {
            // si on est sur le sous-domaine valority
            if (useRequestURL().hostname.includes('capacite-emprunt') || location?.host?.includes('capacite-emprunt')) {
                return;
            }

            await useVersionStore().initVersion();

            if (this.isLoggedIn === true) {
                this.fetchNewsletter();
            }
        },
        async nuxtClientInitToken() {
            // si on est sur le sous-domaine valority
            if (useRequestURL().hostname.includes('capacite-emprunt') || location?.host?.includes('capacite-emprunt')) {
                return;
            }

            await this.getOrFetchToken();
        },
        updateUserToken() {
            // si on est sur le sous-domaine valority
            if (useRequestURL().hostname.includes('capacite-emprunt') || location?.host?.includes('capacite-emprunt')) {
                return;
            }

            return new Promise((resolve) => {
                useNuxtApp().$axios.get("/user/token").then(({data}) => {
                    this.updateStorageUserToken(data.token);
                }).catch((e) => {
                    console.error(e);
                }).finally(() => {
                    resolve();
                });
            })
        },
        updateStorageUserToken(val, versionNeededUpdate = true) {
            window?.localStorage?.setItem("userToken", val);

            // Pour eviter de l'update avant l'init axios
            if (versionNeededUpdate) {
                useVersionStore().initVersion();
            }
        },
        setTutoReaded() {
            this.tutoReaded = true;
        }
    }
});

export default defineNuxtPlugin(async function () {
    const router = useRouter();

    function scrollToHash(hash) {
        const targetElement = document.querySelector(hash);

        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: "smooth"
            });

            return new Promise(resolve => setTimeout(resolve, 1000));
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    router.beforeEach((to) => {
        if (to.hash) {
            return scrollToHash(to.hash);
        }

        return scrollToHash("#top");
    });
});

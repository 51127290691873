<template>
    <svg viewBox="0 0 29 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 3.6C0 1.61633 1.56245 0 3.48 0C5.39755 0 6.96 1.61633 6.96 3.6C6.96 5.58367 5.39755 7.23673 3.48 7.23673C1.56245 7.23673 0 5.5898 0 3.6ZM0.479388 30V9.97347H6.49245V30H0.479388ZM22.9929 30V20.2531C22.9929 17.9265 22.9455 14.951 19.868 14.951C16.7904 14.951 16.2637 17.4735 16.2637 20.0878V30.0061H10.2565V9.97347H16.021V12.7041H16.1039C16.9088 11.1306 18.8678 9.47143 21.7914 9.47143C27.8755 9.47143 28.9941 13.6163 28.9941 18.998V30H22.9869H22.9929Z" fill="currentColor"/>
    </svg>
</template>

<script>
export default {
    name: "LinkedIn"
}
</script>

<style scoped>

</style>

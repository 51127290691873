export function ellipsisOn(string, nbWords) {
    let arrayString = string.split(' ');

    let newString = '';

    arrayString.forEach((item, id) => {
        if (id < nbWords) {
            newString += (newString !== '') ? ' ' + item : item;
        } else if (id === nbWords) {
            newString += " ...";
        }
    })

    return newString;
}

export function ellipsisOnChar(content, maxLength) {
    let trimmedString = content.substr(0, maxLength);

    return content.length < maxLength ? content : trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))) + ' ...';
}

/** Retourne un string avec son article accordé pour un nom de ville ou nom de personne <br>
 * Params (strings) OBLIGATOIRES (sinon retourne undefined) : nom propre, article ("à" ou "de")
 */
export function useVowelChecker(string, article) {
    if (string == null) {
        return '';
    }

    const multiWords = string.split(' ').length > 1;
    const [firstWord, restOfWords] = string.split(' ');

    if (article === 'de') {
        if (multiWords === true) {
            if (firstWord === 'Le') {
                return `du ${restOfWords}`;
            } else if (firstWord === 'Les') {
                return `des ${restOfWords}`;
            }
        }

        const vowels = ['a', 'e', 'i', 'o', 'u', 'é', 'è', 'à'];
        return vowels.includes(string.toLowerCase()[0]) ? `d'${string}` : `de ${string}`;
    } else if (article === 'à') {
        if (multiWords === true) {
            if (firstWord === 'Le') {
                return `au ${restOfWords}`;
            } else if (firstWord === 'Les') {
                return `aux ${restOfWords}`;
            }
        }

        return `à ${string}`;
    }
}

/** Retourne un objet URL créé à partir d'un string (param 1) et d'un array de paramètres sous forme [{key: key, value: value}] (param 2) */
export function urlBuilder(rawUrl, params) {
    let url = new URL(rawUrl);

    params.forEach(param => {
        url.searchParams.append(param.key, param.value);
    });

    return url;
}

export function useEditorParser(stringEditor) {
    let response = stringEditor;

    // Pool de regex pour parser l'editor, les index sont ceux renvoyer par une regex global
    const poolOfPatterns = [
        {pattern: /\[link lien="([a-zA-Z0-9-.?&;_#=:\/]*)"\]([^\[\]]*)\[\/link\]/, replaceStr: `<a class="underline" target="_blank" href="$1">$2</a>`},
        {pattern: /\[btn lien="([a-zA-Z0-9-.?&;_#=:\/]*)" bg="([a-zA-Z0-9-\/#\(\)]*)"\]([^\[\]]*)\[\/btn\]/, replaceStr: `<a href="$1" style="background-color: $2;" class="rounded px-4 py-3 sm:text-sm">$3</a>`},
    ];

    if (response !== '' && response !== undefined && response !== null) {
        for (const pattern of poolOfPatterns) {
            //match toute les valeurs du pattern
            const globalValuesMatch = response.match(new RegExp(pattern.pattern, 'gm'));

            if (globalValuesMatch?.length > 0) {
                for (const valueMatch of globalValuesMatch) {
                    //match valeur par valeur
                    const stringMatch = valueMatch.match(new RegExp(pattern.pattern));

                    //match et remplace tout les $X du pattern
                    const mapping = [...pattern.replaceStr.matchAll(/\$(\d+)/g)];
                    let dataMapping = pattern.replaceStr;

                    for (const change of mapping) {
                        dataMapping = dataMapping.replace(change[0], `${decodeHTMLEntities(stringMatch[change[1]])}`);
                    }

                    response = response.replace(stringMatch[0], dataMapping);
                }
            }
        }
    }

    return response;
}

export function decodeHTMLEntities(text) {
    let entities = [
        ['amp', '&'],
        ['apos', '\''],
        ['#x27', '\''],
        ['#x2F', '/'],
        ['#39', '\''],
        ['#47', '/'],
        ['lt', '<'],
        ['gt', '>'],
        ['nbsp', ' '],
        ['quot', '"']
    ];

    for (let i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

    return text;
}

/** Pour mettre au pluriel : ajoute un s à chaque mot si la value est supérieure à 1 */
export function useAddS(words, value) {
    const wordsArray = words.split(' ');

    wordsArray.forEach((word, index) => {
        if (value > 1) {
            wordsArray[index] += 's';
        }
    });

    return wordsArray.join(' ');
}

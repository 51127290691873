//@ts-nocheck
//déso mais le fichier souligné en rouge dans phpstorm ca me gave

export function capitalize(value: string) {
    if (!value) return '';

    return value.toString().toUpperCase();
}

export function capitalizeFirstLetter(value: string) {
    if (!value) return '';

    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function price(value: number, maximumFractionDigits: number = 2, minimumFractionDigits?: number) {
    if (isNaN(value)) return '';

    if (minimumFractionDigits == null) {
        minimumFractionDigits = maximumFractionDigits;
    }

    return new Intl.NumberFormat('fr-FR', {
        style: 'decimal',
        currency: 'EUR',
        maximumFractionDigits: maximumFractionDigits,
        minimumFractionDigits: minimumFractionDigits
    }).format(value).replaceAll(' ', ' ');
}

export function frenchizer(value: number) {
    if (isNaN(value)) return '';

    return `${new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 2}).format(value)}`
}

export function textLimit(value: string | null, limit?: number, limitText?: string) {
    if (value == null) {
        return null;
    }

    if (limit === undefined) {
        return value;
    }

    if (limitText === undefined) {
        limitText = '...';
    }

    let subString = value.substring(0, limit);

    if (subString.length < limit) {
        return subString;
    }

    return subString.trim() + limitText;
}

export function formatDate(date: any, format = 'DD/MM/YYYY') {
    return useNuxtApp().$moment(date).format(format);
}

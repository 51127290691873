import axios from "axios";
import https from "https";
import {useUserStore} from "~/store/user";

export default defineNuxtPlugin({
    name: 'axios',
    dependsOn: ['token'],
    setup(nuxtApp) {
        let config = {
            withCredentials: true,
            baseURL: nuxtApp.$config.public['baseUrl']
        };

        if (process.client) {
            if (localStorage.getItem("userToken")) {
                config.headers = {
                    common: {
                        'X-USER-TOKEN': localStorage.getItem("userToken")
                    }
                }
            }
        } else {
            if (useUserStore().token !== null) {
                config.headers = {
                    common: {
                        'X-USER-TOKEN': useUserStore().token
                    }
                }
            }
        }

        let cookies = useRequestHeaders(['cookie']);

        if (cookies != null && cookies.cookie != null) {
            if (config.headers == null) {
                config.headers = {};
            }

            config.headers.cookie = cookies.cookie;
        }

        if (process.env.NODE_ENV === 'development' && !process.client) {
            config.httpsAgent = new https.Agent({ rejectUnauthorized: false });
        }

        let axiosClient = axios.create(config);

        axiosClient.interceptors.response.use(r => r, (error) => {
            if (error.response == null) {
                return;
            }
            if (error.response.status === 401 && process.client && !error.config.url.includes('connexion')) { //fixe un peu degueu pour connexion a voir
                window.location = document.location.origin;
            }

            return Promise.reject(error);
        });

        return {
            provide: {
                axios: axiosClient,
            },
        };
    }
});

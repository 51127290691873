/** Retourne les paramètres d'URL à partir d'une url en string */
export function getUrlParams(url) {
    let urlParams = new URLSearchParams(url.search);
    let params = {};

    urlParams.forEach((value, key) => {
        params[key] = value;
    });

    return params;
}

export function getUrlReseaux() {
    return {
        instagram: "https://www.instagram.com/maslow.immo/",
        tiktok: "https://www.tiktok.com/@maslow.immo",
        linkedIn: "https://www.linkedin.com/company/maslowimmo/",
        youtube: 'https://www.youtube.com/channel/UCVlX9yrcktr6gOrzVrIdXlg'
    }
}

export function getUserAgent(rawUserAgent) {
    try {
        const ua = rawUserAgent.toLowerCase();

        const getProbableBrowser = () => {
            if (ua.includes('firefox')) {
                return 'firefox';
            } else if (ua.includes('opera')) {
                return 'opera';
            } else if (ua.includes('edg')) {
                return 'edge';
            } else if (ua.includes('chrome')) {
                return 'chrome';
            } else if (ua.includes('safari')) {
                return 'safari';
            }

            return null;
        }

        const getProbableDevice = () => {
            if (ua.includes('android')) {
                return 'android';
            } else if (ua.includes('iPhone')) {
                return 'iPhone';
            } else if (ua.includes('iPad')) {
                return 'iPad';
            } else if (ua.includes('ios')) {
                return 'iOS';
            }

            return 'desktop';
        }

        return {
            browser: getProbableBrowser(),
            device: getProbableDevice(),
            raw: ua
        }
    } catch (e) {
        console.error(e);

        return {};
    }
}

export function useRemoveHash() {
    window.history.replaceState(
        history.state,
        null,
        history.state.current.split('#')[0]
    );
}
export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('swipe', {
        beforeMount(el, binding) {
            const touchPoint = {
                x: null,
                y: null,
            };

            const emit = (v) => {
                binding.value(v);
            };

            el.touchStartHandler = (e) => {
                //e.preventDefault();
                touchPoint.x = e.touches[0].clientX;
                touchPoint.y = e.touches[0].clientY;
            };

            el.touchMoveHandler = (e) => {
                if (!touchPoint.x || !touchPoint.y) return;
                let xDiff = touchPoint.x - e.touches[0].clientX;
                let yDiff = touchPoint.y - e.touches[0].clientY;
                if (Math.abs(xDiff) < 25 && Math.abs(yDiff) < 25) {
                    return;
                }

                if (Math.abs(xDiff) >= Math.abs(yDiff)) {
                    e.stopPropagation();
                    if (xDiff > 0) emit('right');
                    else emit('left');
                } else {
                    if (yDiff > 0) emit('bottom');
                    else emit('top');
                }

                touchPoint.x = null;
                touchPoint.y = null;
            };

            el.addEventListener('touchstart', el.touchStartHandler);
            el.addEventListener('touchmove', el.touchMoveHandler);
        },
        unmounted(el) {
            el.removeEventListener('touchstart', el.touchStartHandler);
            el.removeEventListener('touchmove', el.touchMoveHandler);
        }
    });

    nuxtApp.vueApp.directive('click-outside', {
        beforeMount(el, binding, vnode) {
            binding.event = function (event) {
                if (!(el === event.target || el.contains(event.target))) {
                    if (binding.value instanceof Function) {
                        binding.value(event)
                    }
                }
            }
            document.body.addEventListener('click', binding.event)
        },
        unmounted(el, binding) {
            document.body.removeEventListener('click', binding.event)
        }
    });
});

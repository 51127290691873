<template>
    <div class="overlay-container" v-if="conversation != null && attachementId != null">
        <img alt="attachment image" :src="$axios.defaults.baseURL + `/chat/conversation/${conversation.id}/attachment/${attachementId}/show`">

        <div class="toolbar">
            <div class="cursor-pointer" @click="download">
                <icons-download-solid class="w-5 h-5 sm:w-7 sm:h-7"></icons-download-solid>
            </div>

            <div class="cursor-pointer" @click="$emit('close')">
                <icons-x-solid class="w-5 h-5 sm:w-7 sm:h-7"></icons-x-solid>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImagePreview",
    props: {
        conversation: {},
        attachementId: {}
    },
    methods: {
        download() {
            window.open(useNuxtApp().$axios.defaults.baseURL + `/chat/conversation/${this.conversation.id}/attachment/${this.attachementId}/download`, '_blank')
        }
    },
}
</script>

<style scoped lang="scss">
.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(10, 10, 10, 0.75);
    z-index: 99999;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 95vw;
        max-height: 95vh;
    }

    .toolbar {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.6vw;
        margin-right: 0.2vw;

        background-color: #0a0a0a;
        position: fixed;
        right: 0;
        top: 0;
        color: white;
        padding: 0.5vw;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}
</style>
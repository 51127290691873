import mitt from 'mitt';
const emitter = mitt();

export default defineNuxtPlugin((nuxtApp) => {
    return {
        provide: {
            emit: emitter.emit,
            on: emitter.on,
            off: emitter.off
        }
    };
});

<template>
    <div ref="accordeon" class="accordeon" :style="cssVars">
        <slot v-if="openAccordeon"></slot>
    </div>
</template>

<script>
export default {
    name: "Accordeon",
    data() {
        return {
            maxHeight: 0,
            scrollHeight: 0
        }
    },
    props: {
        openAccordeon: {
            type: Boolean,
            default: false
        },
        time: {
            type: String,
            default: '.5s'
        }
    },
    computed: {
        cssVars() {
            return {
                '--time--accordeon': this.time,
                '--max-height--accordeon': this.openAccordeon ? this.scrollHeight + "px" : 0
            }
        }
    },
    watch: {
        openAccordeon() {
            this.calcMaxHeight();
        }
    },
    methods: {
        calcMaxHeight() {
            if (this.openAccordeon === true) {
                this.$nextTick(() => {
                    this.maxHeight = this.scrollHeight + "px";
                });

                return;
            }

            this.maxHeight = 0;
        }
    },
    mounted() {
        // TODO: Fixer l'accordeon
        this.scrollHeight = this.$refs.accordeon.scrollHeight;
        this.calcMaxHeight();
    }
}
</script>

<style scoped lang="scss">
.accordeon {
    //max-height: var(--max-height--accordeon);
    //overflow: hidden;
    //transition: max-height ease-in-out var(--time--accordeon);
}
</style>

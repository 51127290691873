import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import security_45global from "/app/middleware/security.global.js";
import url_45log_45global from "/app/middleware/url-log.global.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  security_45global,
  url_45log_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.js")
}
import { Buffer } from 'buffer'; //utile malgré le warning

export default defineNuxtRouteMiddleware((to) => {
    if (process.server) return
    let path = to.path.split('/');

    if (path.includes('ma-reservation') || path.includes('mon-financement')) {
        if (localStorage.getItem("userToken") != null) {
            useNuxtApp().$axios.post(
                '/ma-reservation/verify',
                {path: Buffer.from(to.fullPath).toString('base64')},
            ).then(({data}) => {
                if (!data) {
                    return navigateTo({name: 'mon-compte-mon-bien'});
                }
            }).catch((e) => {
                return navigateTo({name: 'mon-compte-mon-bien'});
            });
        } else {
            return navigateTo({name: 'connexion'});
        }
    }
});

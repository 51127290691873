<template>
    <div>
        <div v-if="showed" class="fixed w-14 h-14 bg-tangerine rounded-full flex cursor-pointer btn-shadow items-center justify-center align-middle select-none z-[999999]"
             @click="toggleChat" :class="[slightlyUpClass]"
        >
            <c-loader color="white" v-if="loading || configHolidays === null"></c-loader>

            <img
                v-else-if="closed"
                src="/images/chat/chat-bubble.svg"
                alt="chat icon"
                class="w-9 h-9 block select-none">

            <icons-chevron-down-outline v-else class="w-9 h-9 block select-none text-white"></icons-chevron-down-outline>
        </div>

        <div v-show="!closed && !loading" v-if="configHolidays !== null">
            <chat-dialogue
                class="z-[999999]"
                :closed="closed"
                :error="error"
                :config-holidays="configHolidays"
                :is-chat-online="isChatOnline"
                @close="toggleChat">
            </chat-dialogue>
        </div>
    </div>
</template>

<script>
import useGtmTrackEvent from "~/composables/gtmTracking";
import {useConfigurationsStore} from "~/store/configurations";

export default {
    name: "Floating",
    data: () => ({
        closed: true,
        loading: true,
        isChatOnline: false,
        error: false,
        lastKnownToken: null,
        tokenCheckerInterval: null,
        configHolidays: null,
        showed: false
    }),
    props:{
        slightlyUp: {
            type: Boolean
        }
    },
    methods: {
        fetchConfig(){
            return useConfigurationsStore().getConfigs(['vacances']).then((data) => {
                this.configHolidays = data[0].config;
            });
        },
        initChat(token) {
            if (useNuxtApp().$socket.connected) {
                useNuxtApp().$socket.disconnect();
            }

            if (this.lastKnownToken == null) {
                this.lastKnownToken = token;
            }

            useNuxtApp().$socket.auth = { token };
            useNuxtApp().$socket.connect();

            useNuxtApp().$socket.on("onlineState", (state) => {
                this.isChatOnline = this.configHolidays.state === true ? false : state;
            });

            useNuxtApp().$socket.on("session", ({ token }) => {
                useNuxtApp().$socket.auth = { token };

                this.loading = false;
                this.error = false;
            });

            useNuxtApp().$socket.on('connect_error', (e) => {
                this.error = true;
            });

            clearInterval(this.tokenCheckerInterval);

            this.tokenCheckerInterval = setInterval(() => {
                if (this.lastKnownToken !== localStorage.getItem("userToken")) {
                    this.loading = true;
                    useNuxtApp().$socket.close();

                    this.lastKnownToken = localStorage.getItem("userToken");
                    this.initChat(localStorage.getItem("userToken"));
                }
            }, 1000);
        },
        checkTokenAvailability() {
            let token = localStorage.getItem("userToken");

            if (token) {
                this.fetchConfig().then(() => this.initChat(token));
            } else {
                this.checkTokenAvailabilityTimeout();
            }
        },
        checkTokenAvailabilityTimeout() {
            setTimeout(() => {
                this.checkTokenAvailability();
            }, 200);
        },
        onReset() {
            useNuxtApp().$socket.close();

            this.checkTokenAvailability();
        },
        toggleChat() {
            this.closed = !this.closed;

            useGtmTrackEvent({
                event: 'chat',
                chat_interaction: 'chat ' + (this.closed ? 'closed' : 'opened')
            });

            localStorage.setItem("chatShown", this.closed ? "false" : "true");

            if (document.body && this.isMobile === true) {
                document.body.style.setProperty('overflow', this.closed ? 'auto' : 'hidden');
            }
        }
    },
    created() {
        if (process.client) {
            let chatShown = localStorage.getItem("chatShown") === "true";
            if (localStorage.getItem("chatShown") == null) {
                chatShown = false;

                localStorage.setItem("chatShown", "false");
            }

            this.closed = !chatShown;
        }

        useNuxtApp().$on('open-chat', () => {
           this.closed = false;
           if (document.body && this.isMobile === true) {
               document.body.style.setProperty('overflow', 'hidden');
           }
        })

        useNuxtApp().$on('set_chat_visibility', (val) => {
            this.showed = val;
        })
    },
    mounted() {
        this.loading = true;

        if (process.client) {
            this.checkTokenAvailability();
        }

        this.showed = true;
    },
    computed: {
        isMobile() {
            return useNuxtApp().$isMobile?.value;
        },
        slightlyUpClass() {
            return this.slightlyUp ? 'bottom-[6.5rem] right-4' : 'bottom-6 right-6';
        }
    },
    beforeDestroy() {
        //this.$nuxt.$off('open-chat');

        useNuxtApp().$socket.off("onlineState");
        useNuxtApp().$socket.off("session");
        useNuxtApp().$socket.off("connect_error");

        useNuxtApp().$socket.close();
    }
}
</script>

<style scoped lang="scss">
.btn-shadow {
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.25);
}
</style>

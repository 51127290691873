<template>
    <div class="min-h-screen flex flex-col">
        <NuxtLoadingIndicator :color="colorStyles.raw.primary"></NuxtLoadingIndicator>

        <NuxtLayout>
            <NuxtPage/>
        </NuxtLayout>
    </div>
</template>

<script>
export default {
    name: 'app',
    data: () => ({
        from: 'maslow'
    }),
    computed: {
        isMobile() {
            return useNuxtApp().$isMobile?.value;
        },
        colorStyles() {
            const themes = {
                maslow: {
                    primary: '#F56E58'
                },
                valority: {
                    primary: '#A58862'
                }
            };

            let styles = {
                'raw': {}
            };

            for (const [k, v] of Object.entries(themes[this.from])) {
                styles['raw'][k] = v;
            }

            return styles;
        }
    },
    created() {
        if (useRequestHeaders().host != null) {
            this.from = useRequestHeaders().host.includes('capacite-emprunt') ? 'valority' : 'maslow';
        } else if (window != null) {
            this.from = window.location.host.includes('capacite-emprunt') ? 'valority' : 'maslow';
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="w-3.5 h-3.5 rounded-full p-0.5" :class="bgColor">
        <div class="w-2.5 h-2.5 rounded-full" :class="classes">

        </div>
    </div>
</template>

<script>
export default {
    name: "StatusIndicator",
    props: {
        type: {
            type: String,
            default: "normal"
        },
        white: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        bgColor() {
            return this.white ? 'bg-white' : 'bg-grey4';
        },
        classes() {
            if (this.type === "disabled") {
                return "bg-grey5";
            }

            if (this.type === "away") {
                return "bg-[#FFBF42]";
            }

            if (this.type === "error") {
                return "bg-[#F80808]";
            }

            return "bg-[#0DC209]";
        }
    }
}
</script>

<style scoped lang="scss">

</style>
import { default as equipe4TOPmC44mTMeta } from "/app/pages/a-propos/equipe.vue?macro=true";
import { default as indexKFBsy7B5dSMeta } from "/app/pages/a-propos/index.vue?macro=true";
import { default as indexvKTzgAulEHMeta } from "/app/pages/catalogue/[slug]/[id]/index.vue?macro=true";
import { default as infosWwcDPS8mApMeta } from "/app/pages/catalogue/[slug]/[id]/infos.vue?macro=true";
import { default as rendez_45vousO3xFQbJZvCMeta } from "/app/pages/catalogue/[slug]/[id]/rendez-vous.vue?macro=true";
import { default as index98aaDbFn8rMeta } from "/app/pages/catalogue/index.vue?macro=true";
import { default as indexGNO0RsHCYLMeta } from "/app/pages/cgu/index.vue?macro=true";
import { default as confirmation_45suppressionxi42iIJcg0Meta } from "/app/pages/confirmation-suppression.vue?macro=true";
import { default as indexnlbdSHtEe8Meta } from "/app/pages/connexion/index.vue?macro=true";
import { default as indexzkpPc0jla8Meta } from "/app/pages/connexion/merci/index.vue?macro=true";
import { default as _91token_93Tq8DelHOLSMeta } from "/app/pages/connexion/réinitialiser-mot-de-passe/[token].vue?macro=true";
import { default as indexS79sHgPk1pMeta } from "/app/pages/emails/[token]/index.vue?macro=true";
import { default as errorcxo3GvC8JMMeta } from "/app/pages/error.vue?macro=true";
import { default as _91name_93WzSCkZnddyMeta } from "/app/pages/icons/[name].vue?macro=true";
import { default as rendez_45vousvztFs05ZExMeta } from "/app/pages/iframe/rendez-vous.vue?macro=true";
import { default as indexyJfG5f1wSfMeta } from "/app/pages/ils-parlent-de-nous/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as confirmation_45compteVFpOhNN10cMeta } from "/app/pages/inscription/confirmation-compte.vue?macro=true";
import { default as indextLtITQUY8GMeta } from "/app/pages/investir-a-[ville]/index.vue?macro=true";
import { default as indexLU8z1QBS5ZMeta } from "/app/pages/je-me-lance/index.vue?macro=true";
import { default as indextGgauB8jXeMeta } from "/app/pages/ma-reservation/analyse-patrimoniale/index.vue?macro=true";
import { default as index5wVa92zgp2Meta } from "/app/pages/ma-reservation/depot-garantie/index.vue?macro=true";
import { default as indexfAvFbvMzubMeta } from "/app/pages/ma-reservation/dossier-complet/index.vue?macro=true";
import { default as index7M08nbdGxvMeta } from "/app/pages/ma-reservation/financement/index.vue?macro=true";
import { default as indexx0aOWxXvmJMeta } from "/app/pages/ma-reservation/index.vue?macro=true";
import { default as indexiHFMaT8OYCMeta } from "/app/pages/ma-reservation/informations-personnelles/index.vue?macro=true";
import { default as indexHflCf1aMJOMeta } from "/app/pages/ma-reservation/kit-fiscal/index.vue?macro=true";
import { default as indexdvFK11vyRTMeta } from "/app/pages/ma-reservation/mandat-de-gestion/index.vue?macro=true";
import { default as index8StjVIYRXyMeta } from "/app/pages/ma-reservation/signature/index.vue?macro=true";
import { default as index3uRXvkyOxwMeta } from "/app/pages/ma-reservation/signature/mandat-gestion/index.vue?macro=true";
import { default as index9wXY6O5iFLMeta } from "/app/pages/ma-reservation/signature/success/index.vue?macro=true";
import { default as index8jyHT9Cc80Meta } from "/app/pages/ma-reservation/upload-documents/index.vue?macro=true";
import { default as indexdjfcWKoqYMMeta } from "/app/pages/ma-reservation/validation/index.vue?macro=true";
import { default as _91lng_93aaHtuQj2pxMeta } from "/app/pages/map/[lat]/[lng].vue?macro=true";
import { default as index39rP0fxt9VMeta } from "/app/pages/mentions-legales/index.vue?macro=true";
import { default as faqt7hytjSrQCMeta } from "/app/pages/mon-compte/faq.vue?macro=true";
import { default as frise_45de_45l_45investissementC3BAO7iunnMeta } from "/app/pages/mon-compte/frise-de-l-investissement.vue?macro=true";
import { default as indexDR2jR9ZSWaMeta } from "/app/pages/mon-compte/index.vue?macro=true";
import { default as la_45chaine_45maslowIVKvONNZlYMeta } from "/app/pages/mon-compte/la-chaine-maslow.vue?macro=true";
import { default as recus_45signessUqgq2uHmnMeta } from "/app/pages/mon-compte/mes-documents/recus-signes.vue?macro=true";
import { default as transmettremqN9GhvvJfMeta } from "/app/pages/mon-compte/mes-documents/transmettre.vue?macro=true";
import { default as mes_45documentsccEq75lbykMeta } from "/app/pages/mon-compte/mes-documents.vue?macro=true";
import { default as mes_45favorisTn3hjxdE26Meta } from "/app/pages/mon-compte/mes-favoris.vue?macro=true";
import { default as analyse_45patrimonialeNrJqgjuJ2OMeta } from "/app/pages/mon-compte/mes-informations/analyse-patrimoniale.vue?macro=true";
import { default as etat_45civilt3VYQ3puyEMeta } from "/app/pages/mon-compte/mes-informations/etat-civil.vue?macro=true";
import { default as indexhg430781CtMeta } from "/app/pages/mon-compte/mes-informations/index.vue?macro=true";
import { default as mon_45compte1namV9PUB4Meta } from "/app/pages/mon-compte/mes-informations/mon-compte.vue?macro=true";
import { default as mes_45informations240PCbDf2sMeta } from "/app/pages/mon-compte/mes-informations.vue?macro=true";
import { default as mes_45recherchesaI9FGpNDk0Meta } from "/app/pages/mon-compte/mes-recherches.vue?macro=true";
import { default as indexzY8khxF5yPMeta } from "/app/pages/mon-compte/mes-rendez-vous/index.vue?macro=true";
import { default as indexjpvzsH7jUmMeta } from "/app/pages/mon-compte/mes-rendez-vous/prendre-rendez-vous/index.vue?macro=true";
import { default as mes_45simulationsK96mQdDkC7Meta } from "/app/pages/mon-compte/mes-simulations.vue?macro=true";
import { default as mon_45biengF0eoCun0AMeta } from "/app/pages/mon-compte/mon-bien.vue?macro=true";
import { default as mon_45panierisDviX9eulMeta } from "/app/pages/mon-compte/mon-panier.vue?macro=true";
import { default as mon_45parrainagebqgz7ILJK1Meta } from "/app/pages/mon-compte/mon-parrainage.vue?macro=true";
import { default as indexDXS1lmqHI5Meta } from "/app/pages/mon-compte/tableau-de-bord/index.vue?macro=true";
import { default as mon_45compteDUHooABJtaMeta } from "/app/pages/mon-compte.vue?macro=true";
import { default as indexP6mm99on2EMeta } from "/app/pages/mon-financement/dates-cles/index.vue?macro=true";
import { default as indexGIyYVdDXDXMeta } from "/app/pages/mon-financement/origine-fonds/index.vue?macro=true";
import { default as indexfHPTnrzdCiMeta } from "/app/pages/mon-financement/selection-offre/index.vue?macro=true";
import { default as indexA7Iux3gv7SMeta } from "/app/pages/mon-financement/selection-offre/selection-validee/index.vue?macro=true";
import { default as indexLmSlNwLxObMeta } from "/app/pages/mon-financement/signature/index.vue?macro=true";
import { default as indexjFr0kkuN5yMeta } from "/app/pages/mon-financement/upload-documents/index.vue?macro=true";
import { default as NotFound1gV95Fb6xkMeta } from "/app/pages/NotFound.vue?macro=true";
import { default as indexpsXHQUS9FIMeta } from "/app/pages/nous-contacter/contacter-maslow/index.vue?macro=true";
import { default as indexyS4xojOBJFMeta } from "/app/pages/nous-contacter/index.vue?macro=true";
import { default as index77LOedZFOTMeta } from "/app/pages/nous-contacter/poser-une-question/index.vue?macro=true";
import { default as index0wQdEfOFVLMeta } from "/app/pages/nous-contacter/prendre-rendez-vous/index.vue?macro=true";
import { default as indexHRVh3bVnOZMeta } from "/app/pages/propos/index.vue?macro=true";
import { default as validate3dcLrynw1eMeta } from "/app/pages/rendez-vous/[uid]/validate.vue?macro=true";
import { default as _91_46_46_46uri_93t7bSmHBXXLMeta } from "/app/pages/ressources/[...uri].vue?macro=true";
import { default as indexfj6zMbyPFjMeta } from "/app/pages/ressources/index.vue?macro=true";
import { default as indexlxUWs0cdAqMeta } from "/app/pages/service-cle-en-main/choisir-et-reserver/index.vue?macro=true";
import { default as indexeIsEgvHbEhMeta } from "/app/pages/service-cle-en-main/definir-mon-profil/index.vue?macro=true";
import { default as indexnMlVTqqBroMeta } from "/app/pages/service-cle-en-main/financer/index.vue?macro=true";
import { default as indexoy7PapnNTGMeta } from "/app/pages/service-cle-en-main/percevoir-mes-loyers/index.vue?macro=true";
import { default as indexFGacQYyJz1Meta } from "/app/pages/service-cle-en-main/piloter-mon-investissement/index.vue?macro=true";
import { default as indexFQhPg1nIuBMeta } from "/app/pages/service-cle-en-main/suivre-la-livraison/index.vue?macro=true";
import { default as indexu11CvB2FBhMeta } from "/app/pages/service-cle-en-main/tous-nos-services-en-un-coup-d-oeil/index.vue?macro=true";
import { default as service_45cle_45en_45mainiocnB1XAuWMeta } from "/app/pages/service-cle-en-main.vue?macro=true";
import { default as index2lcbl0ZnPgMeta } from "/app/pages/simulation/index.vue?macro=true";
import { default as resultatsYIzFQja1oWMeta } from "/app/pages/simulation/resultats.vue?macro=true";
import { default as _91slug_932SyIxWbGTXMeta } from "/app/pages/success-story/details/[slug].vue?macro=true";
import { default as indexe7JSFAaBeQMeta } from "/app/pages/success-story/index.vue?macro=true";
import { default as _91slug_93VKHzDhJFpQMeta } from "/app/pages/ville/[slug].vue?macro=true";
import { default as investissement_45locatif00FpewfZw0Meta } from "/app/pages/ville/investissement-locatif.vue?macro=true";
export default [
  {
    name: equipe4TOPmC44mTMeta?.name ?? "a-propos-equipe",
    path: equipe4TOPmC44mTMeta?.path ?? "/a-propos/equipe",
    meta: equipe4TOPmC44mTMeta || {},
    alias: equipe4TOPmC44mTMeta?.alias || [],
    redirect: equipe4TOPmC44mTMeta?.redirect,
    component: () => import("/app/pages/a-propos/equipe.vue").then(m => m.default || m)
  },
  {
    name: indexKFBsy7B5dSMeta?.name ?? "a-propos",
    path: indexKFBsy7B5dSMeta?.path ?? "/a-propos",
    meta: indexKFBsy7B5dSMeta || {},
    alias: indexKFBsy7B5dSMeta?.alias || [],
    redirect: indexKFBsy7B5dSMeta?.redirect,
    component: () => import("/app/pages/a-propos/index.vue").then(m => m.default || m)
  },
  {
    name: indexvKTzgAulEHMeta?.name ?? "catalogue-slug-id",
    path: indexvKTzgAulEHMeta?.path ?? "/catalogue/:slug()/:id()",
    meta: indexvKTzgAulEHMeta || {},
    alias: indexvKTzgAulEHMeta?.alias || [],
    redirect: indexvKTzgAulEHMeta?.redirect,
    component: () => import("/app/pages/catalogue/[slug]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: infosWwcDPS8mApMeta?.name ?? "catalogue-slug-id-infos",
    path: infosWwcDPS8mApMeta?.path ?? "/catalogue/:slug()/:id()/infos",
    meta: infosWwcDPS8mApMeta || {},
    alias: infosWwcDPS8mApMeta?.alias || [],
    redirect: infosWwcDPS8mApMeta?.redirect,
    component: () => import("/app/pages/catalogue/[slug]/[id]/infos.vue").then(m => m.default || m)
  },
  {
    name: rendez_45vousO3xFQbJZvCMeta?.name ?? "catalogue-slug-id-rendez-vous",
    path: rendez_45vousO3xFQbJZvCMeta?.path ?? "/catalogue/:slug()/:id()/rendez-vous",
    meta: rendez_45vousO3xFQbJZvCMeta || {},
    alias: rendez_45vousO3xFQbJZvCMeta?.alias || [],
    redirect: rendez_45vousO3xFQbJZvCMeta?.redirect,
    component: () => import("/app/pages/catalogue/[slug]/[id]/rendez-vous.vue").then(m => m.default || m)
  },
  {
    name: index98aaDbFn8rMeta?.name ?? "catalogue",
    path: index98aaDbFn8rMeta?.path ?? "/catalogue",
    meta: index98aaDbFn8rMeta || {},
    alias: index98aaDbFn8rMeta?.alias || [],
    redirect: index98aaDbFn8rMeta?.redirect,
    component: () => import("/app/pages/catalogue/index.vue").then(m => m.default || m)
  },
  {
    name: indexGNO0RsHCYLMeta?.name ?? "cgu",
    path: indexGNO0RsHCYLMeta?.path ?? "/cgu",
    meta: indexGNO0RsHCYLMeta || {},
    alias: indexGNO0RsHCYLMeta?.alias || [],
    redirect: indexGNO0RsHCYLMeta?.redirect,
    component: () => import("/app/pages/cgu/index.vue").then(m => m.default || m)
  },
  {
    name: confirmation_45suppressionxi42iIJcg0Meta?.name ?? "confirmation-suppression",
    path: confirmation_45suppressionxi42iIJcg0Meta?.path ?? "/confirmation-suppression",
    meta: confirmation_45suppressionxi42iIJcg0Meta || {},
    alias: confirmation_45suppressionxi42iIJcg0Meta?.alias || [],
    redirect: confirmation_45suppressionxi42iIJcg0Meta?.redirect,
    component: () => import("/app/pages/confirmation-suppression.vue").then(m => m.default || m)
  },
  {
    name: indexnlbdSHtEe8Meta?.name ?? "connexion",
    path: indexnlbdSHtEe8Meta?.path ?? "/connexion",
    meta: indexnlbdSHtEe8Meta || {},
    alias: indexnlbdSHtEe8Meta?.alias || [],
    redirect: indexnlbdSHtEe8Meta?.redirect,
    component: () => import("/app/pages/connexion/index.vue").then(m => m.default || m)
  },
  {
    name: indexzkpPc0jla8Meta?.name ?? "connexion-merci",
    path: indexzkpPc0jla8Meta?.path ?? "/connexion/merci",
    meta: indexzkpPc0jla8Meta || {},
    alias: indexzkpPc0jla8Meta?.alias || [],
    redirect: indexzkpPc0jla8Meta?.redirect,
    component: () => import("/app/pages/connexion/merci/index.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Tq8DelHOLSMeta?.name ?? "connexion-réinitialiser-mot-de-passe-token",
    path: _91token_93Tq8DelHOLSMeta?.path ?? "/connexion/r%C3%A9initialiser-mot-de-passe/:token()",
    meta: _91token_93Tq8DelHOLSMeta || {},
    alias: _91token_93Tq8DelHOLSMeta?.alias || [],
    redirect: _91token_93Tq8DelHOLSMeta?.redirect,
    component: () => import("/app/pages/connexion/réinitialiser-mot-de-passe/[token].vue").then(m => m.default || m)
  },
  {
    name: indexS79sHgPk1pMeta?.name ?? "emails-token",
    path: indexS79sHgPk1pMeta?.path ?? "/emails/:token()",
    meta: indexS79sHgPk1pMeta || {},
    alias: indexS79sHgPk1pMeta?.alias || [],
    redirect: indexS79sHgPk1pMeta?.redirect,
    component: () => import("/app/pages/emails/[token]/index.vue").then(m => m.default || m)
  },
  {
    name: errorcxo3GvC8JMMeta?.name ?? "error",
    path: errorcxo3GvC8JMMeta?.path ?? "/error",
    meta: errorcxo3GvC8JMMeta || {},
    alias: errorcxo3GvC8JMMeta?.alias || [],
    redirect: errorcxo3GvC8JMMeta?.redirect,
    component: () => import("/app/pages/error.vue").then(m => m.default || m)
  },
  {
    name: _91name_93WzSCkZnddyMeta?.name ?? "icons-name",
    path: _91name_93WzSCkZnddyMeta?.path ?? "/icons/:name()",
    meta: _91name_93WzSCkZnddyMeta || {},
    alias: _91name_93WzSCkZnddyMeta?.alias || [],
    redirect: _91name_93WzSCkZnddyMeta?.redirect,
    component: () => import("/app/pages/icons/[name].vue").then(m => m.default || m)
  },
  {
    name: rendez_45vousvztFs05ZExMeta?.name ?? "iframe-rendez-vous",
    path: rendez_45vousvztFs05ZExMeta?.path ?? "/iframe/rendez-vous",
    meta: rendez_45vousvztFs05ZExMeta || {},
    alias: rendez_45vousvztFs05ZExMeta?.alias || [],
    redirect: rendez_45vousvztFs05ZExMeta?.redirect,
    component: () => import("/app/pages/iframe/rendez-vous.vue").then(m => m.default || m)
  },
  {
    name: indexyJfG5f1wSfMeta?.name ?? "ils-parlent-de-nous",
    path: indexyJfG5f1wSfMeta?.path ?? "/ils-parlent-de-nous",
    meta: indexyJfG5f1wSfMeta || {},
    alias: indexyJfG5f1wSfMeta?.alias || [],
    redirect: indexyJfG5f1wSfMeta?.redirect,
    component: () => import("/app/pages/ils-parlent-de-nous/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: confirmation_45compteVFpOhNN10cMeta?.name ?? "inscription-confirmation-compte",
    path: confirmation_45compteVFpOhNN10cMeta?.path ?? "/inscription/confirmation-compte",
    meta: confirmation_45compteVFpOhNN10cMeta || {},
    alias: confirmation_45compteVFpOhNN10cMeta?.alias || [],
    redirect: confirmation_45compteVFpOhNN10cMeta?.redirect,
    component: () => import("/app/pages/inscription/confirmation-compte.vue").then(m => m.default || m)
  },
  {
    name: indextLtITQUY8GMeta?.name ?? "investir-a-ville",
    path: indextLtITQUY8GMeta?.path ?? "/investir-a-:ville()",
    meta: indextLtITQUY8GMeta || {},
    alias: indextLtITQUY8GMeta?.alias || [],
    redirect: indextLtITQUY8GMeta?.redirect,
    component: () => import("/app/pages/investir-a-[ville]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLU8z1QBS5ZMeta?.name ?? "je-me-lance",
    path: indexLU8z1QBS5ZMeta?.path ?? "/je-me-lance",
    meta: indexLU8z1QBS5ZMeta || {},
    alias: indexLU8z1QBS5ZMeta?.alias || [],
    redirect: indexLU8z1QBS5ZMeta?.redirect,
    component: () => import("/app/pages/je-me-lance/index.vue").then(m => m.default || m)
  },
  {
    name: indextGgauB8jXeMeta?.name ?? "ma-reservation-analyse-patrimoniale",
    path: indextGgauB8jXeMeta?.path ?? "/ma-reservation/analyse-patrimoniale",
    meta: indextGgauB8jXeMeta || {},
    alias: indextGgauB8jXeMeta?.alias || [],
    redirect: indextGgauB8jXeMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/analyse-patrimoniale/index.vue").then(m => m.default || m)
  },
  {
    name: index5wVa92zgp2Meta?.name ?? "ma-reservation-depot-garantie",
    path: index5wVa92zgp2Meta?.path ?? "/ma-reservation/depot-garantie",
    meta: index5wVa92zgp2Meta || {},
    alias: index5wVa92zgp2Meta?.alias || [],
    redirect: index5wVa92zgp2Meta?.redirect,
    component: () => import("/app/pages/ma-reservation/depot-garantie/index.vue").then(m => m.default || m)
  },
  {
    name: indexfAvFbvMzubMeta?.name ?? "ma-reservation-dossier-complet",
    path: indexfAvFbvMzubMeta?.path ?? "/ma-reservation/dossier-complet",
    meta: indexfAvFbvMzubMeta || {},
    alias: indexfAvFbvMzubMeta?.alias || [],
    redirect: indexfAvFbvMzubMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/dossier-complet/index.vue").then(m => m.default || m)
  },
  {
    name: index7M08nbdGxvMeta?.name ?? "ma-reservation-financement",
    path: index7M08nbdGxvMeta?.path ?? "/ma-reservation/financement",
    meta: index7M08nbdGxvMeta || {},
    alias: index7M08nbdGxvMeta?.alias || [],
    redirect: index7M08nbdGxvMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/financement/index.vue").then(m => m.default || m)
  },
  {
    name: indexx0aOWxXvmJMeta?.name ?? "ma-reservation",
    path: indexx0aOWxXvmJMeta?.path ?? "/ma-reservation",
    meta: indexx0aOWxXvmJMeta || {},
    alias: indexx0aOWxXvmJMeta?.alias || [],
    redirect: indexx0aOWxXvmJMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/index.vue").then(m => m.default || m)
  },
  {
    name: indexiHFMaT8OYCMeta?.name ?? "ma-reservation-informations-personnelles",
    path: indexiHFMaT8OYCMeta?.path ?? "/ma-reservation/informations-personnelles",
    meta: indexiHFMaT8OYCMeta || {},
    alias: indexiHFMaT8OYCMeta?.alias || [],
    redirect: indexiHFMaT8OYCMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/informations-personnelles/index.vue").then(m => m.default || m)
  },
  {
    name: indexHflCf1aMJOMeta?.name ?? "ma-reservation-kit-fiscal",
    path: indexHflCf1aMJOMeta?.path ?? "/ma-reservation/kit-fiscal",
    meta: indexHflCf1aMJOMeta || {},
    alias: indexHflCf1aMJOMeta?.alias || [],
    redirect: indexHflCf1aMJOMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/kit-fiscal/index.vue").then(m => m.default || m)
  },
  {
    name: indexdvFK11vyRTMeta?.name ?? "ma-reservation-mandat-de-gestion",
    path: indexdvFK11vyRTMeta?.path ?? "/ma-reservation/mandat-de-gestion",
    meta: indexdvFK11vyRTMeta || {},
    alias: indexdvFK11vyRTMeta?.alias || [],
    redirect: indexdvFK11vyRTMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/mandat-de-gestion/index.vue").then(m => m.default || m)
  },
  {
    name: index8StjVIYRXyMeta?.name ?? "ma-reservation-signature",
    path: index8StjVIYRXyMeta?.path ?? "/ma-reservation/signature",
    meta: index8StjVIYRXyMeta || {},
    alias: index8StjVIYRXyMeta?.alias || [],
    redirect: index8StjVIYRXyMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/signature/index.vue").then(m => m.default || m)
  },
  {
    name: index3uRXvkyOxwMeta?.name ?? "ma-reservation-signature-mandat-gestion",
    path: index3uRXvkyOxwMeta?.path ?? "/ma-reservation/signature/mandat-gestion",
    meta: index3uRXvkyOxwMeta || {},
    alias: index3uRXvkyOxwMeta?.alias || [],
    redirect: index3uRXvkyOxwMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/signature/mandat-gestion/index.vue").then(m => m.default || m)
  },
  {
    name: index9wXY6O5iFLMeta?.name ?? "ma-reservation-signature-success",
    path: index9wXY6O5iFLMeta?.path ?? "/ma-reservation/signature/success",
    meta: index9wXY6O5iFLMeta || {},
    alias: index9wXY6O5iFLMeta?.alias || [],
    redirect: index9wXY6O5iFLMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/signature/success/index.vue").then(m => m.default || m)
  },
  {
    name: index8jyHT9Cc80Meta?.name ?? "ma-reservation-upload-documents",
    path: index8jyHT9Cc80Meta?.path ?? "/ma-reservation/upload-documents",
    meta: index8jyHT9Cc80Meta || {},
    alias: index8jyHT9Cc80Meta?.alias || [],
    redirect: index8jyHT9Cc80Meta?.redirect,
    component: () => import("/app/pages/ma-reservation/upload-documents/index.vue").then(m => m.default || m)
  },
  {
    name: indexdjfcWKoqYMMeta?.name ?? "ma-reservation-validation",
    path: indexdjfcWKoqYMMeta?.path ?? "/ma-reservation/validation",
    meta: indexdjfcWKoqYMMeta || {},
    alias: indexdjfcWKoqYMMeta?.alias || [],
    redirect: indexdjfcWKoqYMMeta?.redirect,
    component: () => import("/app/pages/ma-reservation/validation/index.vue").then(m => m.default || m)
  },
  {
    name: _91lng_93aaHtuQj2pxMeta?.name ?? "map-lat-lng",
    path: _91lng_93aaHtuQj2pxMeta?.path ?? "/map/:lat()/:lng()",
    meta: _91lng_93aaHtuQj2pxMeta || {},
    alias: _91lng_93aaHtuQj2pxMeta?.alias || [],
    redirect: _91lng_93aaHtuQj2pxMeta?.redirect,
    component: () => import("/app/pages/map/[lat]/[lng].vue").then(m => m.default || m)
  },
  {
    name: index39rP0fxt9VMeta?.name ?? "mentions-legales",
    path: index39rP0fxt9VMeta?.path ?? "/mentions-legales",
    meta: index39rP0fxt9VMeta || {},
    alias: index39rP0fxt9VMeta?.alias || [],
    redirect: index39rP0fxt9VMeta?.redirect,
    component: () => import("/app/pages/mentions-legales/index.vue").then(m => m.default || m)
  },
  {
    name: mon_45compteDUHooABJtaMeta?.name ?? undefined,
    path: mon_45compteDUHooABJtaMeta?.path ?? "/mon-compte",
    meta: mon_45compteDUHooABJtaMeta || {},
    alias: mon_45compteDUHooABJtaMeta?.alias || [],
    redirect: mon_45compteDUHooABJtaMeta?.redirect,
    component: () => import("/app/pages/mon-compte.vue").then(m => m.default || m),
    children: [
  {
    name: faqt7hytjSrQCMeta?.name ?? "mon-compte-faq",
    path: faqt7hytjSrQCMeta?.path ?? "faq",
    meta: faqt7hytjSrQCMeta || {},
    alias: faqt7hytjSrQCMeta?.alias || [],
    redirect: faqt7hytjSrQCMeta?.redirect,
    component: () => import("/app/pages/mon-compte/faq.vue").then(m => m.default || m)
  },
  {
    name: frise_45de_45l_45investissementC3BAO7iunnMeta?.name ?? "mon-compte-frise-de-l-investissement",
    path: frise_45de_45l_45investissementC3BAO7iunnMeta?.path ?? "frise-de-l-investissement",
    meta: frise_45de_45l_45investissementC3BAO7iunnMeta || {},
    alias: frise_45de_45l_45investissementC3BAO7iunnMeta?.alias || [],
    redirect: frise_45de_45l_45investissementC3BAO7iunnMeta?.redirect,
    component: () => import("/app/pages/mon-compte/frise-de-l-investissement.vue").then(m => m.default || m)
  },
  {
    name: indexDR2jR9ZSWaMeta?.name ?? "mon-compte",
    path: indexDR2jR9ZSWaMeta?.path ?? "",
    meta: indexDR2jR9ZSWaMeta || {},
    alias: indexDR2jR9ZSWaMeta?.alias || [],
    redirect: indexDR2jR9ZSWaMeta?.redirect,
    component: () => import("/app/pages/mon-compte/index.vue").then(m => m.default || m)
  },
  {
    name: la_45chaine_45maslowIVKvONNZlYMeta?.name ?? "mon-compte-la-chaine-maslow",
    path: la_45chaine_45maslowIVKvONNZlYMeta?.path ?? "la-chaine-maslow",
    meta: la_45chaine_45maslowIVKvONNZlYMeta || {},
    alias: la_45chaine_45maslowIVKvONNZlYMeta?.alias || [],
    redirect: la_45chaine_45maslowIVKvONNZlYMeta?.redirect,
    component: () => import("/app/pages/mon-compte/la-chaine-maslow.vue").then(m => m.default || m)
  },
  {
    name: mes_45documentsccEq75lbykMeta?.name ?? "mon-compte-mes-documents",
    path: mes_45documentsccEq75lbykMeta?.path ?? "mes-documents",
    meta: mes_45documentsccEq75lbykMeta || {},
    alias: mes_45documentsccEq75lbykMeta?.alias || [],
    redirect: mes_45documentsccEq75lbykMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-documents.vue").then(m => m.default || m),
    children: [
  {
    name: recus_45signessUqgq2uHmnMeta?.name ?? "mon-compte-mes-documents-recus-signes",
    path: recus_45signessUqgq2uHmnMeta?.path ?? "recus-signes",
    meta: recus_45signessUqgq2uHmnMeta || {},
    alias: recus_45signessUqgq2uHmnMeta?.alias || [],
    redirect: recus_45signessUqgq2uHmnMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-documents/recus-signes.vue").then(m => m.default || m)
  },
  {
    name: transmettremqN9GhvvJfMeta?.name ?? "mon-compte-mes-documents-transmettre",
    path: transmettremqN9GhvvJfMeta?.path ?? "transmettre",
    meta: transmettremqN9GhvvJfMeta || {},
    alias: transmettremqN9GhvvJfMeta?.alias || [],
    redirect: transmettremqN9GhvvJfMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-documents/transmettre.vue").then(m => m.default || m)
  }
]
  },
  {
    name: mes_45favorisTn3hjxdE26Meta?.name ?? "mon-compte-mes-favoris",
    path: mes_45favorisTn3hjxdE26Meta?.path ?? "mes-favoris",
    meta: mes_45favorisTn3hjxdE26Meta || {},
    alias: mes_45favorisTn3hjxdE26Meta?.alias || [],
    redirect: mes_45favorisTn3hjxdE26Meta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-favoris.vue").then(m => m.default || m)
  },
  {
    name: mes_45informations240PCbDf2sMeta?.name ?? undefined,
    path: mes_45informations240PCbDf2sMeta?.path ?? "mes-informations",
    meta: mes_45informations240PCbDf2sMeta || {},
    alias: mes_45informations240PCbDf2sMeta?.alias || [],
    redirect: mes_45informations240PCbDf2sMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-informations.vue").then(m => m.default || m),
    children: [
  {
    name: analyse_45patrimonialeNrJqgjuJ2OMeta?.name ?? "mon-compte-mes-informations-analyse-patrimoniale",
    path: analyse_45patrimonialeNrJqgjuJ2OMeta?.path ?? "analyse-patrimoniale",
    meta: analyse_45patrimonialeNrJqgjuJ2OMeta || {},
    alias: analyse_45patrimonialeNrJqgjuJ2OMeta?.alias || [],
    redirect: analyse_45patrimonialeNrJqgjuJ2OMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-informations/analyse-patrimoniale.vue").then(m => m.default || m)
  },
  {
    name: etat_45civilt3VYQ3puyEMeta?.name ?? "mon-compte-mes-informations-etat-civil",
    path: etat_45civilt3VYQ3puyEMeta?.path ?? "etat-civil",
    meta: etat_45civilt3VYQ3puyEMeta || {},
    alias: etat_45civilt3VYQ3puyEMeta?.alias || [],
    redirect: etat_45civilt3VYQ3puyEMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-informations/etat-civil.vue").then(m => m.default || m)
  },
  {
    name: indexhg430781CtMeta?.name ?? "mon-compte-mes-informations",
    path: indexhg430781CtMeta?.path ?? "",
    meta: indexhg430781CtMeta || {},
    alias: indexhg430781CtMeta?.alias || [],
    redirect: indexhg430781CtMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-informations/index.vue").then(m => m.default || m)
  },
  {
    name: mon_45compte1namV9PUB4Meta?.name ?? "mon-compte-mes-informations-mon-compte",
    path: mon_45compte1namV9PUB4Meta?.path ?? "mon-compte",
    meta: mon_45compte1namV9PUB4Meta || {},
    alias: mon_45compte1namV9PUB4Meta?.alias || [],
    redirect: mon_45compte1namV9PUB4Meta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-informations/mon-compte.vue").then(m => m.default || m)
  }
]
  },
  {
    name: mes_45recherchesaI9FGpNDk0Meta?.name ?? "mon-compte-mes-recherches",
    path: mes_45recherchesaI9FGpNDk0Meta?.path ?? "mes-recherches",
    meta: mes_45recherchesaI9FGpNDk0Meta || {},
    alias: mes_45recherchesaI9FGpNDk0Meta?.alias || [],
    redirect: mes_45recherchesaI9FGpNDk0Meta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-recherches.vue").then(m => m.default || m)
  },
  {
    name: indexzY8khxF5yPMeta?.name ?? "mon-compte-mes-rendez-vous",
    path: indexzY8khxF5yPMeta?.path ?? "mes-rendez-vous",
    meta: indexzY8khxF5yPMeta || {},
    alias: indexzY8khxF5yPMeta?.alias || [],
    redirect: indexzY8khxF5yPMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-rendez-vous/index.vue").then(m => m.default || m)
  },
  {
    name: indexjpvzsH7jUmMeta?.name ?? "mon-compte-mes-rendez-vous-prendre-rendez-vous",
    path: indexjpvzsH7jUmMeta?.path ?? "mes-rendez-vous/prendre-rendez-vous",
    meta: indexjpvzsH7jUmMeta || {},
    alias: indexjpvzsH7jUmMeta?.alias || [],
    redirect: indexjpvzsH7jUmMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-rendez-vous/prendre-rendez-vous/index.vue").then(m => m.default || m)
  },
  {
    name: mes_45simulationsK96mQdDkC7Meta?.name ?? "mon-compte-mes-simulations",
    path: mes_45simulationsK96mQdDkC7Meta?.path ?? "mes-simulations",
    meta: mes_45simulationsK96mQdDkC7Meta || {},
    alias: mes_45simulationsK96mQdDkC7Meta?.alias || [],
    redirect: mes_45simulationsK96mQdDkC7Meta?.redirect,
    component: () => import("/app/pages/mon-compte/mes-simulations.vue").then(m => m.default || m)
  },
  {
    name: mon_45biengF0eoCun0AMeta?.name ?? "mon-compte-mon-bien",
    path: mon_45biengF0eoCun0AMeta?.path ?? "mon-bien",
    meta: mon_45biengF0eoCun0AMeta || {},
    alias: mon_45biengF0eoCun0AMeta?.alias || [],
    redirect: mon_45biengF0eoCun0AMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mon-bien.vue").then(m => m.default || m)
  },
  {
    name: mon_45panierisDviX9eulMeta?.name ?? "mon-compte-mon-panier",
    path: mon_45panierisDviX9eulMeta?.path ?? "mon-panier",
    meta: mon_45panierisDviX9eulMeta || {},
    alias: mon_45panierisDviX9eulMeta?.alias || [],
    redirect: mon_45panierisDviX9eulMeta?.redirect,
    component: () => import("/app/pages/mon-compte/mon-panier.vue").then(m => m.default || m)
  },
  {
    name: mon_45parrainagebqgz7ILJK1Meta?.name ?? "mon-compte-mon-parrainage",
    path: mon_45parrainagebqgz7ILJK1Meta?.path ?? "mon-parrainage",
    meta: mon_45parrainagebqgz7ILJK1Meta || {},
    alias: mon_45parrainagebqgz7ILJK1Meta?.alias || [],
    redirect: mon_45parrainagebqgz7ILJK1Meta?.redirect,
    component: () => import("/app/pages/mon-compte/mon-parrainage.vue").then(m => m.default || m)
  },
  {
    name: indexDXS1lmqHI5Meta?.name ?? "mon-compte-tableau-de-bord",
    path: indexDXS1lmqHI5Meta?.path ?? "tableau-de-bord",
    meta: indexDXS1lmqHI5Meta || {},
    alias: indexDXS1lmqHI5Meta?.alias || [],
    redirect: indexDXS1lmqHI5Meta?.redirect,
    component: () => import("/app/pages/mon-compte/tableau-de-bord/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexP6mm99on2EMeta?.name ?? "mon-financement-dates-cles",
    path: indexP6mm99on2EMeta?.path ?? "/mon-financement/dates-cles",
    meta: indexP6mm99on2EMeta || {},
    alias: indexP6mm99on2EMeta?.alias || [],
    redirect: indexP6mm99on2EMeta?.redirect,
    component: () => import("/app/pages/mon-financement/dates-cles/index.vue").then(m => m.default || m)
  },
  {
    name: indexGIyYVdDXDXMeta?.name ?? "mon-financement-origine-fonds",
    path: indexGIyYVdDXDXMeta?.path ?? "/mon-financement/origine-fonds",
    meta: indexGIyYVdDXDXMeta || {},
    alias: indexGIyYVdDXDXMeta?.alias || [],
    redirect: indexGIyYVdDXDXMeta?.redirect,
    component: () => import("/app/pages/mon-financement/origine-fonds/index.vue").then(m => m.default || m)
  },
  {
    name: indexfHPTnrzdCiMeta?.name ?? "mon-financement-selection-offre",
    path: indexfHPTnrzdCiMeta?.path ?? "/mon-financement/selection-offre",
    meta: indexfHPTnrzdCiMeta || {},
    alias: indexfHPTnrzdCiMeta?.alias || [],
    redirect: indexfHPTnrzdCiMeta?.redirect,
    component: () => import("/app/pages/mon-financement/selection-offre/index.vue").then(m => m.default || m)
  },
  {
    name: indexA7Iux3gv7SMeta?.name ?? "mon-financement-selection-offre-selection-validee",
    path: indexA7Iux3gv7SMeta?.path ?? "/mon-financement/selection-offre/selection-validee",
    meta: indexA7Iux3gv7SMeta || {},
    alias: indexA7Iux3gv7SMeta?.alias || [],
    redirect: indexA7Iux3gv7SMeta?.redirect,
    component: () => import("/app/pages/mon-financement/selection-offre/selection-validee/index.vue").then(m => m.default || m)
  },
  {
    name: indexLmSlNwLxObMeta?.name ?? "mon-financement-signature",
    path: indexLmSlNwLxObMeta?.path ?? "/mon-financement/signature",
    meta: indexLmSlNwLxObMeta || {},
    alias: indexLmSlNwLxObMeta?.alias || [],
    redirect: indexLmSlNwLxObMeta?.redirect,
    component: () => import("/app/pages/mon-financement/signature/index.vue").then(m => m.default || m)
  },
  {
    name: indexjFr0kkuN5yMeta?.name ?? "mon-financement-upload-documents",
    path: indexjFr0kkuN5yMeta?.path ?? "/mon-financement/upload-documents",
    meta: indexjFr0kkuN5yMeta || {},
    alias: indexjFr0kkuN5yMeta?.alias || [],
    redirect: indexjFr0kkuN5yMeta?.redirect,
    component: () => import("/app/pages/mon-financement/upload-documents/index.vue").then(m => m.default || m)
  },
  {
    name: NotFound1gV95Fb6xkMeta?.name ?? "NotFound",
    path: NotFound1gV95Fb6xkMeta?.path ?? "/NotFound",
    meta: NotFound1gV95Fb6xkMeta || {},
    alias: NotFound1gV95Fb6xkMeta?.alias || [],
    redirect: NotFound1gV95Fb6xkMeta?.redirect,
    component: () => import("/app/pages/NotFound.vue").then(m => m.default || m)
  },
  {
    name: indexpsXHQUS9FIMeta?.name ?? "nous-contacter-contacter-maslow",
    path: indexpsXHQUS9FIMeta?.path ?? "/nous-contacter/contacter-maslow",
    meta: indexpsXHQUS9FIMeta || {},
    alias: indexpsXHQUS9FIMeta?.alias || [],
    redirect: indexpsXHQUS9FIMeta?.redirect,
    component: () => import("/app/pages/nous-contacter/contacter-maslow/index.vue").then(m => m.default || m)
  },
  {
    name: indexyS4xojOBJFMeta?.name ?? "nous-contacter",
    path: indexyS4xojOBJFMeta?.path ?? "/nous-contacter",
    meta: indexyS4xojOBJFMeta || {},
    alias: indexyS4xojOBJFMeta?.alias || [],
    redirect: indexyS4xojOBJFMeta?.redirect,
    component: () => import("/app/pages/nous-contacter/index.vue").then(m => m.default || m)
  },
  {
    name: index77LOedZFOTMeta?.name ?? "nous-contacter-poser-une-question",
    path: index77LOedZFOTMeta?.path ?? "/nous-contacter/poser-une-question",
    meta: index77LOedZFOTMeta || {},
    alias: index77LOedZFOTMeta?.alias || [],
    redirect: index77LOedZFOTMeta?.redirect,
    component: () => import("/app/pages/nous-contacter/poser-une-question/index.vue").then(m => m.default || m)
  },
  {
    name: index0wQdEfOFVLMeta?.name ?? "nous-contacter-prendre-rendez-vous",
    path: index0wQdEfOFVLMeta?.path ?? "/nous-contacter/prendre-rendez-vous",
    meta: index0wQdEfOFVLMeta || {},
    alias: index0wQdEfOFVLMeta?.alias || [],
    redirect: index0wQdEfOFVLMeta?.redirect,
    component: () => import("/app/pages/nous-contacter/prendre-rendez-vous/index.vue").then(m => m.default || m)
  },
  {
    name: indexHRVh3bVnOZMeta?.name ?? "propos",
    path: indexHRVh3bVnOZMeta?.path ?? "/propos",
    meta: indexHRVh3bVnOZMeta || {},
    alias: indexHRVh3bVnOZMeta?.alias || [],
    redirect: indexHRVh3bVnOZMeta?.redirect,
    component: () => import("/app/pages/propos/index.vue").then(m => m.default || m)
  },
  {
    name: validate3dcLrynw1eMeta?.name ?? "rendez-vous-uid-validate",
    path: validate3dcLrynw1eMeta?.path ?? "/rendez-vous/:uid()/validate",
    meta: validate3dcLrynw1eMeta || {},
    alias: validate3dcLrynw1eMeta?.alias || [],
    redirect: validate3dcLrynw1eMeta?.redirect,
    component: () => import("/app/pages/rendez-vous/[uid]/validate.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46uri_93t7bSmHBXXLMeta?.name ?? "ressources-uri",
    path: _91_46_46_46uri_93t7bSmHBXXLMeta?.path ?? "/ressources/:uri(.*)*",
    meta: _91_46_46_46uri_93t7bSmHBXXLMeta || {},
    alias: _91_46_46_46uri_93t7bSmHBXXLMeta?.alias || [],
    redirect: _91_46_46_46uri_93t7bSmHBXXLMeta?.redirect,
    component: () => import("/app/pages/ressources/[...uri].vue").then(m => m.default || m)
  },
  {
    name: indexfj6zMbyPFjMeta?.name ?? "ressources",
    path: indexfj6zMbyPFjMeta?.path ?? "/ressources",
    meta: indexfj6zMbyPFjMeta || {},
    alias: indexfj6zMbyPFjMeta?.alias || [],
    redirect: indexfj6zMbyPFjMeta?.redirect,
    component: () => import("/app/pages/ressources/index.vue").then(m => m.default || m)
  },
  {
    name: service_45cle_45en_45mainiocnB1XAuWMeta?.name ?? "service-cle-en-main",
    path: service_45cle_45en_45mainiocnB1XAuWMeta?.path ?? "/service-cle-en-main",
    meta: service_45cle_45en_45mainiocnB1XAuWMeta || {},
    alias: service_45cle_45en_45mainiocnB1XAuWMeta?.alias || [],
    redirect: service_45cle_45en_45mainiocnB1XAuWMeta?.redirect,
    component: () => import("/app/pages/service-cle-en-main.vue").then(m => m.default || m),
    children: [
  {
    name: indexlxUWs0cdAqMeta?.name ?? "service-cle-en-main-choisir-et-reserver",
    path: indexlxUWs0cdAqMeta?.path ?? "choisir-et-reserver",
    meta: indexlxUWs0cdAqMeta || {},
    alias: indexlxUWs0cdAqMeta?.alias || [],
    redirect: indexlxUWs0cdAqMeta?.redirect,
    component: () => import("/app/pages/service-cle-en-main/choisir-et-reserver/index.vue").then(m => m.default || m)
  },
  {
    name: indexeIsEgvHbEhMeta?.name ?? "service-cle-en-main-definir-mon-profil",
    path: indexeIsEgvHbEhMeta?.path ?? "definir-mon-profil",
    meta: indexeIsEgvHbEhMeta || {},
    alias: indexeIsEgvHbEhMeta?.alias || [],
    redirect: indexeIsEgvHbEhMeta?.redirect,
    component: () => import("/app/pages/service-cle-en-main/definir-mon-profil/index.vue").then(m => m.default || m)
  },
  {
    name: indexnMlVTqqBroMeta?.name ?? "service-cle-en-main-financer",
    path: indexnMlVTqqBroMeta?.path ?? "financer",
    meta: indexnMlVTqqBroMeta || {},
    alias: indexnMlVTqqBroMeta?.alias || [],
    redirect: indexnMlVTqqBroMeta?.redirect,
    component: () => import("/app/pages/service-cle-en-main/financer/index.vue").then(m => m.default || m)
  },
  {
    name: indexoy7PapnNTGMeta?.name ?? "service-cle-en-main-percevoir-mes-loyers",
    path: indexoy7PapnNTGMeta?.path ?? "percevoir-mes-loyers",
    meta: indexoy7PapnNTGMeta || {},
    alias: indexoy7PapnNTGMeta?.alias || [],
    redirect: indexoy7PapnNTGMeta?.redirect,
    component: () => import("/app/pages/service-cle-en-main/percevoir-mes-loyers/index.vue").then(m => m.default || m)
  },
  {
    name: indexFGacQYyJz1Meta?.name ?? "service-cle-en-main-piloter-mon-investissement",
    path: indexFGacQYyJz1Meta?.path ?? "piloter-mon-investissement",
    meta: indexFGacQYyJz1Meta || {},
    alias: indexFGacQYyJz1Meta?.alias || [],
    redirect: indexFGacQYyJz1Meta?.redirect,
    component: () => import("/app/pages/service-cle-en-main/piloter-mon-investissement/index.vue").then(m => m.default || m)
  },
  {
    name: indexFQhPg1nIuBMeta?.name ?? "service-cle-en-main-suivre-la-livraison",
    path: indexFQhPg1nIuBMeta?.path ?? "suivre-la-livraison",
    meta: indexFQhPg1nIuBMeta || {},
    alias: indexFQhPg1nIuBMeta?.alias || [],
    redirect: indexFQhPg1nIuBMeta?.redirect,
    component: () => import("/app/pages/service-cle-en-main/suivre-la-livraison/index.vue").then(m => m.default || m)
  },
  {
    name: indexu11CvB2FBhMeta?.name ?? "service-cle-en-main-tous-nos-services-en-un-coup-d-oeil",
    path: indexu11CvB2FBhMeta?.path ?? "tous-nos-services-en-un-coup-d-oeil",
    meta: indexu11CvB2FBhMeta || {},
    alias: indexu11CvB2FBhMeta?.alias || [],
    redirect: indexu11CvB2FBhMeta?.redirect,
    component: () => import("/app/pages/service-cle-en-main/tous-nos-services-en-un-coup-d-oeil/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index2lcbl0ZnPgMeta?.name ?? "simulation",
    path: index2lcbl0ZnPgMeta?.path ?? "/simulation",
    meta: index2lcbl0ZnPgMeta || {},
    alias: index2lcbl0ZnPgMeta?.alias || [],
    redirect: index2lcbl0ZnPgMeta?.redirect,
    component: () => import("/app/pages/simulation/index.vue").then(m => m.default || m)
  },
  {
    name: resultatsYIzFQja1oWMeta?.name ?? "simulation-resultats",
    path: resultatsYIzFQja1oWMeta?.path ?? "/simulation/resultats",
    meta: resultatsYIzFQja1oWMeta || {},
    alias: resultatsYIzFQja1oWMeta?.alias || [],
    redirect: resultatsYIzFQja1oWMeta?.redirect,
    component: () => import("/app/pages/simulation/resultats.vue").then(m => m.default || m)
  },
  {
    name: _91slug_932SyIxWbGTXMeta?.name ?? "success-story-details-slug",
    path: _91slug_932SyIxWbGTXMeta?.path ?? "/success-story/details/:slug()",
    meta: _91slug_932SyIxWbGTXMeta || {},
    alias: _91slug_932SyIxWbGTXMeta?.alias || [],
    redirect: _91slug_932SyIxWbGTXMeta?.redirect,
    component: () => import("/app/pages/success-story/details/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexe7JSFAaBeQMeta?.name ?? "success-story",
    path: indexe7JSFAaBeQMeta?.path ?? "/success-story",
    meta: indexe7JSFAaBeQMeta || {},
    alias: indexe7JSFAaBeQMeta?.alias || [],
    redirect: indexe7JSFAaBeQMeta?.redirect,
    component: () => import("/app/pages/success-story/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93VKHzDhJFpQMeta?.name ?? "ville-slug",
    path: _91slug_93VKHzDhJFpQMeta?.path ?? "/ville/:slug()",
    meta: _91slug_93VKHzDhJFpQMeta || {},
    alias: _91slug_93VKHzDhJFpQMeta?.alias || [],
    redirect: _91slug_93VKHzDhJFpQMeta?.redirect,
    component: () => import("/app/pages/ville/[slug].vue").then(m => m.default || m)
  },
  {
    name: investissement_45locatif00FpewfZw0Meta?.name ?? "ville-investissement-locatif",
    path: investissement_45locatif00FpewfZw0Meta?.path ?? "/ville/investissement-locatif",
    meta: investissement_45locatif00FpewfZw0Meta || {},
    alias: investissement_45locatif00FpewfZw0Meta?.alias || [],
    redirect: investissement_45locatif00FpewfZw0Meta?.redirect,
    component: () => import("/app/pages/ville/investissement-locatif.vue").then(m => m.default || m)
  }
]
<template>
    <div>
        <div v-if="error" class="p-4">
            <div class="font-semibold">😰 Oula, nous avons un souci technique !</div>

            <div class="mt-2">
                Nous sommes désolés mais le chat Maslow est en maintenance, merci de réessayer de nous écrire dans un petit moment...
            </div>

            <div class="flex items-center justify-center pt-16">
                <icons-x-circle-outline class="w-24 h-24 text-tangerine-light"></icons-x-circle-outline>
            </div>
        </div>

        <div ref="messagesContainer" class="flex flex-col p-4" v-else-if="conversation != null" >
            <div
                v-for="(message, index) in orderedMessages"
                :key="index"
                class="message flex flex-col mb-3.5"
            >
                <div class="separator" v-if="message.type === 'separator'">
                    <span class="text-grey7 font-normal text-sm">{{ separatorText(message) }}</span>
                </div>

                <div v-if="message.type === 'message'" class="flex flex-row items-center w-fit h-fit mb-2.5 gap-2" :class="{'self-end': message.value.fromMyself, 'flex-row': !message.value.fromMyself, 'flex-row-reverse': message.value.fromMyself}">
                    <div class="rounded-full w-10 h-10 flex items-center justify-center overflow-hidden" :class="{'bg-tangerine-light': message.value.fromMyself, 'bg-grey2': !message.value.fromMyself}">
                        <icons-user-outline class="mt-0.5 w-6 text-tangerine" v-if="message.value.fromMyself"></icons-user-outline>

                        <img v-else class="interlocuteur-image" alt="interlocuteur"
                             :src="$axios.defaults.baseURL+'/userAdmin/'+message.value.messageFrom.userChat.id+'/photo'"
                             onerror="this.src='/images/chat/maslow-logo-black.svg';this.classList.add('p-3')"
                        >
                    </div>

                    <div class="flex flex-col text-sm gap-1">
                        <div class="font-medium text-black" :class="{'text-right': message.value.fromMyself}">
                            <span v-if="!message.value.fromMyself">{{ message.value.messageFrom.userChat.prenom }} {{ message.value.messageFrom.userChat.nom }}</span>
                            <span v-else>Vous</span>
                        </div>

                        <div class="font-normal text-grey7">{{ formatDate(String(message.value.sendedAt), 'HH[:]mm') }}</div>
                    </div>
                </div>

                <div v-if="message.type === 'message'" class="p-3 w-fit h-fit max-w-[95%]"
                    :class="getMessageClasses(message.value.fromMyself)"
                >
                    <div style="overflow-wrap: break-word;">
                        <component class="whitespace-pre-line" target="_blank" v-for="(part, partIndex) in formatAndCleanMessage(message.value.message)" :key="partIndex"
                                   :href="part.type === 'link' ? part.value : undefined"
                                   :is="part.type === 'link' ? 'a' : 'span'"
                                   style="white-space: pre-line; overflow-wrap: break-word;" :class="{'underline': part.type === 'link'}">{{ part.value }}</component>
                    </div>

                    <div v-for="(attachment, index) in message.value.attachments" :key="index" class="max-w-full" style="width: fit-content;">
                        <img v-if="attachment.type === 'file' && isAttachmentImage(attachment)" alt="attachment" class="w-full cursor-pointer" @click="selectedImageForPreview=attachment.id"
                             :src="useNuxtApp().$axios.defaults.baseURL+`/chat/conversation/${conversation.id}/attachment/${attachment.id}/show`"
                        >

                        <div class="mt-2 mr-2 bg-grey6 rounded-full w-fit text-white text-xs px-3.5 py-1 flex flex-row items-center justify-center cursor-pointer max-w-full" @click="downloadAttachment(attachment)" v-else-if="attachment.type === 'file'">
                            <div class="max-w-[90%]" style="overflow-wrap: break-word;">{{ attachment.filename }}</div> <icons-download-outline class="ml-1 w-4 h-4 sm:w-4 sm:h-4"></icons-download-outline>
                        </div>
                    </div>

                    <a
                        v-if="message.value.message.match(teamsRegex)"
                        :href="message.value.message.match(teamsRegex)[0]" target="_blank"
                        class="flex items-center mt-3 gap-x-1 text-blue-700 underline justify-end"
                    >
                        <MSTeams class="h-8 w-8"></MSTeams>
                        Visio Microsoft Teams
                    </a>
                </div>

                <div v-if="message.type === 'offline' && !isChatOnline" class="flex flex-row flex-wrap">
                    <div class="text-base font-semibold mb-2">😴 Chez nous pas de robot !</div>

                    <div class="mb-3">
                        Nos conseillers sont absents en ce moment et ne peuvent pas vous répondre, mais dès qu’ils
                        seront de retour vous aurez votre réponse !
                    </div>

                    <div class="mb-3">Sinon nous vous conseillons aussi :</div>

                    <div class="text-tangerine underline">
                        <a :href="runtimeConfig.blogUrl + '/foire-aux-questions/la-plateforme-maslow'">De consulter notre FAQ</a> <br/>
                        <nuxt-link :to="{name: 'nous-contacter-prendre-rendez-vous'}">De prendre rendez-vous avec un expert</nuxt-link>
                    </div>
                </div>

                <div v-if="message.type === 'holidays' && !isChatOnline"
                     class="flex flex-row flex-wrap"
                     v-html="messageVacances" @click="navigate"
                     :style="`background-color: ${configHolidays.chat.bgColor ?? 'transparent'}`"
                >
                </div>

                <div v-if="message.type === 'first' && isChatOnline" class="flex flex-row flex-wrap">
                    <div class="text-base font-semibold mb-2">🙂 Chez Maslow pas de robot !</div>

                    <div class="mb-3">
                        Tous nos conseillers sont actuellement en ligne. Laissez-nous un numéro de téléphone afin qu'ils
                        puissent vous rappeler.
                    </div>
                </div>

                <div v-if="message.type === 'wait'" class="flex flex-row flex-wrap">
                    <div class="flex flex-row items-center w-fit h-fit mb-2.5 gap-2">
                        <div class="rounded-full w-10 h-10 flex items-center justify-center overflow-hidden bg-grey2">
                            <img class="interlocuteur-image p-3" alt="interlocuteur" src="/images/chat/maslow-logo-black.svg">
                        </div>

                        <div class="flex flex-col text-sm gap-1">
                            <div class="font-medium text-black">
                                <span>Chat Maslow</span>
                            </div>

                            <div class="font-normal text-grey7">{{ message.date.format('HH[:]mm') }}</div>
                        </div>
                    </div>
                </div>

                <div v-if="message.type === 'wait'" class="p-3 w-fit h-fit bg-grey2 rounded-r-md rounded-bl-md">
                    <div class="whitespace-pre-line" v-if="isChatOnline">Merci de patienter quelques instants, un conseiller va vous répondre 🙂</div>
                    <div class="whitespace-pre-line" v-else>Merci pour votre message, nous revenons vers vous dès que l’un de nos conseillers est disponible.</div>
                </div>
            </div>
        </div>

        <chat-image-preview :conversation="conversation" v-if="selectedImageForPreview != null" :attachement-id="selectedImageForPreview" @close="selectedImageForPreview = null"></chat-image-preview>
    </div>
</template>

<script>
import moment from "moment";
import MSTeams from "~/components/logos/MSTeams";
import {useEditorParser} from "~/composables/stringMixin";

export default {
    name: "Messages",
    components: {MSTeams},
    props: {
        conversation: {
            type: [Object, null],
            required: true
        },
        isChatOnline: {
            type: Boolean,
            required: true
        },
        error: {
            type: Boolean,
            required: true
        },
        closed: {
            type: Boolean,
            required: true
        },
        configHolidays: {
            type: [Object, null],
            required: true
        }
    },
    data: () => ({
        selectedImageForPreview: null,
        teamsRegex: /https:\/\/teams\.microsoft\.com\/l\/meetup-join\/\S*/g
    }),
    watch: {
        lastInterlocuteur() {
            this.$emit('interlocuteur', this.lastInterlocuteur);
        },
        conversation: {
            handler() {
                if (process.client) {
                    this.scrollToLastMessage();
                }
            }
        },
        closed: {
            handler() {
                if (process.client && this.closed === false) {
                    this.$nextTick(() => this.scrollToLastMessage());
                }
            },
            immediate: true
        }
    },
    methods: {
        scrollToLastMessage() {
            if (this.$refs.messagesContainer && this.$refs.messagesContainer.children && this.$refs.messagesContainer.children.length > 0) {
                this.$refs.messagesContainer.children[this.$refs.messagesContainer.children.length - 1].scrollIntoView();
            }
        },
        formatAndCleanMessage(message) {
            //On dégage le message teams
            message = message.replace(/https:\/\/teams\.microsoft\.com\/l\/meetup-join\/\S*/g, "");

            let parts = message.split(/(https?:\/\/[^ ]*)/gi);
            let result = [];

            parts.forEach(part => {
                if (part !== '') {
                    const type = /(https?:\/\/[^ ]*)/gi.test(part) ? 'link' : 'string';
                    result.push({ value: part, type: type });
                }
            });

            return result;
        },
        separatorText(separator) {
            if (moment(separator.value.sendedAt).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")) {
                return "Aujourd'hui";
            } else {
                return useNuxtApp().$moment(separator.value.sendedAt).format("dddd DD MMMM YYYY");
            }
        },
        isAttachmentImage(attachment) {
            return (/\.(gif|jpe?g|tiff?|png|webp|bmp|jfif)$/i).test(attachment.filename);
        },
        downloadAttachment(attachment) {
            window.open(useNuxtApp().$axios.defaults.baseURL + `/chat/conversation/${this.conversation.id}/attachment/${attachment.id}/download`, '_blank')
        },
        getMessageClasses(fromMyself) {
            if (fromMyself === true) {
                return 'self-end bg-tangerine-light rounded-l-md rounded-br-md text-end';
            }

            return 'bg-grey2 rounded-r-md rounded-bl-md';
        },
        navigate(event) {
            const href = event.target.getAttribute("href");
            const target = event.target.getAttribute("target");
            if (href && href[0] === "/" && target !== "_blank") {
                event.preventDefault();
                this.$router && this.$router.push(href);
            }
        }
    },
    computed: {
        runtimeConfig() {
            return useRuntimeConfig().public;
        },
        orderedMessages() {
            let orderedMessages = [];
            let dates = [];
            let nbMessages = 0;

            if (this.conversation == null) {
                return [];
            }

            this.conversation.messages.sort((a, b) => {
                return moment(a.sendedAt) - moment(b.sendedAt);
            });

            let offline = false;

            this.conversation.messages.forEach((message) => {
                if (!dates.includes(moment(message.sendedAt).format("DD/MM/YYYY"))) {
                    dates.push(moment(message.sendedAt).format("DD/MM/YYYY"));

                    orderedMessages.push({
                        type: "separator",
                        value: message
                    });
                }

                if (["offline", "holidays"].includes(message.type)) {
                    if (offline === false) {
                        orderedMessages.push({
                            type: this.configHolidays.state === true ? "holidays" : "offline"
                        });

                        offline = true;
                    }

                    return;
                }

                nbMessages++;

                orderedMessages.push({
                    type: "message",
                    value: message,
                    sameUserAsPreviousMessage: orderedMessages[orderedMessages.length-1].type === "message" &&
                        orderedMessages[orderedMessages.length-1].value?.messageFrom?.userChat?.id === message?.messageFrom?.userChat?.id
                });
            });

            if (this.anwseredAtLeastOnce === false && nbMessages >= 1) {
                orderedMessages.push({
                    type: "wait",
                    date: moment()
                });
            }

            if (this.isChatOnline === true && nbMessages === 0) {
                orderedMessages.push({
                    type: "first"
                });
            }

            return orderedMessages;
        },
        anwseredAtLeastOnce() {
            let anwseredAtLeastOnce = false;

            if (this.conversation != null) {
                this.conversation.messages.forEach((message) => {
                    if (message.type == null && !message.fromMyself) {
                        anwseredAtLeastOnce = true;
                    }
                });
            }

            return anwseredAtLeastOnce;
        },
        lastInterlocuteur() {
            let last = null;

            this.orderedMessages.forEach((message) => {
                if (message.type === 'message' && !message.value.fromMyself) {
                    last = message.value.messageFrom.userChat.id;
                }
            });

            return last;
        },
        messageVacances() {
            return useEditorParser(this.configHolidays.chat.text);
        }
    }
}
</script>

<style scoped lang="scss">
.separator {
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    user-select: none;
    width: 100%;

    margin: 1vw 0;

    span {
        position: relative;
        display: inline-block;

        &:before, &:after {
            content: "";
            position: absolute;
            top: 50%;
            width: 9999px;
            height: 1px;
            background: #EBE7E6;
        }

        &:before {
            right: 100%;
            margin-right: 15px;
        }

        &:after {
            left: 100%;
            margin-left: 15px;
        }
    }
}
</style>

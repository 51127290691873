import {ToastContainer} from "vue-toastr";
import {createApp} from "vue";
import 'vue-toastr/dist/style.css';

export default defineNuxtPlugin(nuxtApp => {
    const toast = createApp(ToastContainer);

    const wrapper = document.createElement("div");
    const component = toast.mount(wrapper);

    if (document.body) {
        document.body.appendChild(wrapper);
    }

    return {
        provide: {
            toastr: component,
        },
    };
})

import {defineStore} from 'pinia';

function defaultReservataire() {
    return {
        infosPerso: {
            civilite: {
                isMorale: false,
                civilite: null,
                prenom: null,
                nom: null,
                dateNaissance: null,
                paysNaissance: null,
                lieuNaissance: null,
                cpNaissance: null,
                inseeNaissance: null,
                nationalite: null,
                situation: null,
                regimeMatrimonial: null,
                enfants: null,
                raisonSociale: null,
                siret: null,
                formeJuridique: null,
                autreFormeJuridique: null,
                immatriculationRCS: null,
                activiteExercee: null,
                adresseSiege: null,
                communeSiege: null,
                cpSiege: null,
                inseeSiege: null
            },
            coordonnees: {
                email: null,
                mobile: null,
                adresse_l1: null,
                adresse_l2: null,
                codePostal: null,
                commune: null,
                codeInsee: null,
                pays: null,
                hasCompteEnFrance: null,
                indicatifMobile: null,
                mobileIdentificateurPays: null
            },
            situation: {
                situationEtranger: null,
                hasSecuSociale: null,
                situationProfessionnelle: null,
                secteurActivite: null,
                profession: null,
                customFields: []
            },
        },
        patrimoine: {
            preferences: {
                motivation: null,
            },
            revenus: {
                revenus: [],
                partAnnuelleEpargne: null,
                totalPatrimoine: null,
                repartition: {
                    partImmobilier: null,
                    partLiquidite: null,
                    partEpargne: null,
                    partAssurance: null,
                    partComptesTitres: null,
                    partAutres: null,
                }
            },
            investissements: {
                isIfi: null,
                isProprietaire: null,
                hasResidenceSecondaire: null,
                hasInvestissementLocatif: null,
                typesInvestissementLocatif: null,
                hasInvestissementProduitFinancier: null,
                typesInvestissementProduitFinancier: null
            },
            impots: {
                impots: null,
                reductionImpots: null,
                parts: null,
                credits: []
            }
        }
    };
}

function defaultStructure() {
    return {
        achatADeux: false,
        currentFormName: '',
        "informations-personnellesState": 0,
        "analyse-patrimonialeState": 0,
        financementState: 0,
        "mandat-de-gestionState": 0,
        "kit-fiscalState": 0,
        signatureStep: 0,
        customFields: [],
        modePaiementDepotGarantie: null,
        reservataires: [defaultReservataire()],
        financement: {
            envisage: {
                type: null,
                withCourtierMaslow: null,
                montantCredit: null,
                montantApport: null,
                dureeCredit: null,
                tauxCredit: null,
                preteurs: [
                    null
                ],
                achatComptants: []
            },
            notaire: {
                notaireMaslow: null,
                notaire: {
                    notaireTethys: null,
                    nomNotaire: null,
                    prenomNotaire: null,
                    adresseNotaire: null,
                    communeNotaire: {
                        id: null,
                        cp: null,
                        libelle: null,
                        pays: null,
                    },
                    mailNotaire: null,
                    telephoneNotaire: null,
                }
            }
        },
        mandatGestion: {
            dureeEngagementPinel: null,
            dureeMandat: null,
            hasAssuranceCopro: null,
            hasDelegueReception: null,
            hasExcludeParking: null,
            optionFiscale: null
        },
        kitFiscal: {
            isSalarieOrRetraite: null,
            isResidentFrancais: null,
            hasOtherResidenceMeuble: null,
            hasSubscribed: null,
            hasLotWithIMExpertise: null,
            regimeFiscal: null,
            formeJuridique: null,
            nomCabinet: null,
            adresseCabinet: null,
            nomInterlocuteur: null,
            telephoneInterlocuteur: null,
            emailInterlocuteur: null,
            nomResidence: null,
            numeroSecuSociale: null,
            regimeSecuSociale: null,
            typeRegimeSecuriteSociale: null,
            siren: null,
            autresBiensCoordonnees: null,
            prestationWithIMExpertise: null
        }
    };
}

export const structure = defaultStructure(); //Objet qui sert de référence au niveau de la structure des données, ne doit pas être modifié

function search(path, obj, target) {
    for (const k in obj) {
        if (obj.hasOwnProperty(k)) {
            if ((["number", "string", "boolean"].includes(typeof obj[k]) || Array.isArray(obj[k]) || obj[k] === null) && k === target) {
                return path + "." + k
            } else if (typeof obj[k] === "object") {
                const result = search(path + "." + k, obj[k], target);
                if (result) {
                    return result;
                }
            }
        }
    }
    return false;
}

function setField(path, partState, obj, state) {
    for (let field in obj) {
        if (typeof obj[field] !== "object" || Array.isArray(obj[field])) {
            let newPath = search(path, partState, field);

            if (typeof newPath === "string") {
                let explodedPath = newPath.split('.');
                let tempState = state;

                let prop = explodedPath.pop();
                for (let i = 0; i < explodedPath.length; i++) {
                    if (!tempState[explodedPath[i]]) tempState = {};
                    tempState = tempState[explodedPath[i]];
                }

                tempState[prop] = obj[field];
            }
        } else {
            setField(path, partState, obj[field], state)
        }
    }
}

export const useReservationStore = defineStore({
    id: 'reservation-store',
    state: () => (defaultStructure()),
    getters: {
        getCurrentFormName: (state) => state.currentFormName ?? '',
        getState: (state) => state
    },
    actions: {
        setVente(vente) {
            this.$reset();
            this.achatADeux = vente.reservataires?.length === 2;

            for (let objet in vente) {
                switch (objet) {
                    case 'reservataires':
                        for (let i in vente[objet]) {
                            if (!this[objet][i]) {
                                this[objet][i] = defaultReservataire();
                            }

                            setField(objet + "." + i, structure[objet][0], vente[objet][i], this);
                        }
                        break;
                    case 'financement':
                    case 'mandatGestion':
                    case 'kitFiscal':
                        setField(objet, structure[objet], vente[objet], this);
                        break;
                    case 'customFields':
                    case 'modePaiementDepotGarantie':
                        this[objet] = vente[objet];
                        break;
                }
            }

            let varTest = this.achatADeux ? 1 : 0; // Si 2 réservataires, on vérifie les champs du second réservataires

            if (this.financement.notaire.notaire.nomNotaire !== null) {
                this.financement.notaire.notaireMaslow = false;
            } else if (this.financement.notaire.notaire.notaireTethys !== null) {
                this.financement.notaire.notaireMaslow = true;
            }

            this["informations-personnellesState"] = (this.reservataires[varTest].infosPerso.civilite.civilite != null) | (this.reservataires[varTest].infosPerso.coordonnees.email != null) * 2 | (this.reservataires[varTest].infosPerso.situation.situationProfessionnelle != null) * 4;
            if (this.reservataires[varTest].infosPerso.situation.customFields?.length > 0) {
                this["informations-personnellesState"] = this["informations-personnellesState"] | (this.reservataires[varTest].infosPerso.situation.customFields[0]['programmeFormClientChamps'][0].value != null || this.reservataires[varTest].infosPerso.situation.customFields[0]['programmeFormClientChamps'][0].required === false) * 8
            }
            this["analyse-patrimonialeState"] = (this.reservataires[varTest].patrimoine.preferences.motivation != null) | (this.reservataires[varTest].patrimoine.revenus.revenus[0]?.revenuAnnuel != null) * 2 | (this.reservataires[varTest].patrimoine.investissements.isIfi != null) * 4 | (this.reservataires[varTest].patrimoine.impots.impots != null) * 8;
            this.financementState = (this.financement.envisage.type != null) | (vente.financement?.notaireTethys !== null || this.financement.notaire.notaire.nomNotaire !== null) * 2;
            let check = true;
            let exists = false;
            if (this.customFields?.length > 0) {
                exists = true;
                check = this.customFields[0]['programmeFormLotVenteChamps'][0].value != null;
            }
            if (exists) {
                this.financementState = this.financementState | (check) * 4
            }
            this["mandat-de-gestionState"] = (this.mandatGestion.optionFiscale !== null) * 1;
            this["kit-fiscalState"] = (this.kitFiscal.isSalarieOrRetraite != null) * 1;
        },
        addReservataire() {
            if (this.reservataires.length === 1) {
                this.achatADeux = true;
                this.reservataires.push(
                    defaultReservataire()
                );
            }
        },
        deleteReservataire() {
            this.achatADeux = false;
            this.reservataires.pop();

            return useNuxtApp().$axios.post('/ma-reservation/delete/reservataire', {}).then(r => {
                this.setVente(r.data);
            });
        },
        setCurrentFormName(payload) {
            this.currentFormName = payload;
        },
        fetchVente() {
            return useNuxtApp().$axios.get('/ma-reservation/').then(r => {
                this.setVente(r.data[0]);
            });
        },
        saveVente(payload) {
            let data = [];

            if (payload.objet === payload.form && payload.form === payload.step) {
                data = {
                    financement: {...this.financement.envisage, ...this.financement.notaire},
                    reservataires: [...this.reservataires.map((r) => {
                        return {
                            ...r.infosPerso.civilite,
                            ...r.infosPerso.coordonnees,
                            ...r.infosPerso.situation,
                            ...r.patrimoine.preferences,
                            ...r.patrimoine.investissements,
                            ...r.patrimoine.revenus,
                            ...r.patrimoine.impots
                        }
                    })],
                };

                data.customFields = this.customFields;
                data.modePaiementDepotGarantie = this.modePaiementDepotGarantie;

                if (this.mandatGestion.optionFiscale !== null) {
                    data.mandatGestion = this.mandatGestion;
                }

                if (this.kitFiscal.isSalarieOrRetraite !== null) {
                    data.kitFiscal = this.kitFiscal;
                }
            } else if (Array.isArray(this[payload.objet])) {
                if (payload.objet === payload.form) {
                    data = {
                        "customFields": this[payload.objet],
                        "modePaiementDepotGarantie": this.modePaiementDepotGarantie
                    };
                } else {
                    for (let i = 0; i < this[payload.objet].length; i++) {
                        data.push(this[payload.objet][i][payload.form][payload.step]);
                    }
                }
            } else {
                if (payload.step === 'default') {
                    data = this[payload.objet];
                } else {
                    data = this[payload.objet][payload.step];
                }
            }

            return useNuxtApp().$axios.post('/ma-reservation/save/' + payload.objet + '/' + payload.form + '/' + payload.step, data).then(r => {
                this.setVente(r.data);
            });
        }
    }
});

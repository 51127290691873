import {defineNuxtPlugin, useRuntimeConfig} from '#app';
import * as Sentry from '@sentry/vue';
import { CaptureConsole } from "@sentry/integrations";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()

    Sentry.init({
        //enabled: config.public.ENV !== 'dev',
        app: nuxtApp.vueApp,
        autoSessionTracking: false,
        debug: false,
        dsn: "https://2820cf61d38a4e92a7e8b90398ac92d1@sentry.com-company.fr/46",
        release: config.public.VERSION,
        environment: config.public.ENV,
        integrations: [
            new CaptureConsole({
                levels: ['error']
            }),
            /*new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp.$router),
            }),
            new Sentry.Integrations.Breadcrumbs({
                console: false,
            })*/
        ],
        trackComponents: true,
        hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
        logErrors: true,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1,
    })

    return {
        provide: {
            sentry: {
                setContext: Sentry.setContext,
                setUser: Sentry.setUser,
                setTag: Sentry.setTag,
                addBreadcrumb: Sentry.addBreadcrumb,
                captureException: Sentry.captureException,
                captureMessage: Sentry.captureMessage,
            }
        },
    }
})
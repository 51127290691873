<template>
    <div class="tooltip-container" ref="tooltipContainer">
        <slot name="activator" v-bind:on="listeners"></slot>

        <div v-if="shown"
             ref="tooltipContent"
             class="tooltip-content"
             :class="[finalPosition, lineBreak === true ? width + ' !whitespace-normal' : '']"
             :style="{'background-color': backgroundColor, color}"
             @mouseenter="localShow"
             @mouseleave="localHide"
        >
            <svg xmlns="http://www.w3.org/2000/svg"
                 class="w-5 h-5 absolute"
                 :class="classesTriangle[finalPosition]"
                 :fill="backgroundColor"
                 viewBox="0 0 100 100"
            >
                <polygon points="50 15, 100 100, 0 100"/>
            </svg>

            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Tooltip",
    data() {
        return {
            showTooltip: false,
            localShowTooltip: false,
            shown: false,
            finalPosition: "",
            classesTriangle: {
                left: "-right-3 top-1/2 -translate-y-1/2 rotate-90",
                right: "-left-3 top-1/2 -translate-y-1/2 -rotate-90",
                top: "left-1/2 -bottom-3 -translate-x-1/2 -rotate-180",
                bottom: "left-1/2 -top-3 -translate-x-1/2",
                "top left": "-right-3 bottom-0 rotate-90",
                "top right": "-left-3 bottom-0 -rotate-90",
                "bottom left": "-right-3 top-0 rotate-90",
                "bottom right": "-left-3 top-0 -rotate-90"
            }
        }
    },
    props: {
        position: {
            type: String,
            default: 'bottom'
        },
        backgroundColor: {
            type: String,
            default: "#EAEAEA"
        },
        color: {
            type: String,
            default: "black"
        },
        width: {
            type: String,
            default: "max-w-lg"
        },
        lineBreak: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        showTooltip() {
            this.updateShown(this.showTooltip);
        },
        localShowTooltip() {
            this.updateShown(this.localShowTooltip);
        }
    },
    computed: {
        listeners() {
            return {
                mouseenter: () => {
                    this.show();
                },
                mouseleave: () => {
                    this.hide();
                }
            }
        }
    },
    methods: {
        show() {
            this.showTooltip = true;
        },
        hide() {
            this.showTooltip = false;
        },
        localShow() {
            this.localShowTooltip = true;
        },
        localHide() {
            this.localShowTooltip = false;
        },
        updateShown(state) {
            if (state === false) {
                this.shown = false;
            } else {
                this.calcPosAndShow();
            }
        },
        calcPosAndShow() {
            this.shown = true;

            this.$nextTick(() => {
                if (this.$refs.tooltipContent != null) {
                    let boundingRect = this.$refs.tooltipContent.getBoundingClientRect();
                    let height = boundingRect.height;
                    let width = boundingRect.width;
                    const margin = 20;

                    const cantBeRight = boundingRect.right > window.innerWidth - margin,
                        cantBeLeft = boundingRect.left - width < margin,
                        cantBeTop = boundingRect.top - height < margin,
                        cantBeBottom = boundingRect.bottom > window.innerHeight - margin

                    if (this.position === "right") {
                        if (cantBeBottom && !cantBeTop) {
                            this.finalPosition = "top right"
                        } else if (cantBeTop && !cantBeBottom) {
                            this.finalPosition = "bottom right"
                        } else {
                            this.finalPosition = this.position;
                        }
                        if (cantBeRight) {
                            if (this.finalPosition.split(' ').length > 1) {
                                this.finalPosition = this.finalPosition.replace(' right', !cantBeLeft ? " left" : "");
                            } else {
                                this.finalPosition = this.finalPosition.replace('right', !cantBeLeft ? "left" : "bottom");
                            }
                        }
                    } else if (this.position === "left") {
                        if (cantBeBottom && !cantBeTop) {
                            this.finalPosition = "top left"
                        } else if (cantBeTop && cantBeBottom) {
                            this.finalPosition = "bottom left"
                        } else {
                            this.finalPosition = this.position;
                        }
                        if (cantBeLeft) {
                            if (this.finalPosition.split(' ').length > 1) {
                                this.finalPosition = this.finalPosition.replace(' left', !cantBeRight ? " right" : "");
                            } else {
                                this.finalPosition = this.finalPosition.replace('left', !cantBeRight ? "right" : "top");
                            }
                        }
                    } else if (this.position === "top") {
                        if (cantBeLeft && !cantBeRight) {
                            this.finalPosition = "top right"
                        } else if (cantBeRight && !cantBeLeft) {
                            this.finalPosition = "top left"
                        } else {
                            this.finalPosition = this.position;
                        }
                        if (cantBeTop) {
                            if (this.finalPosition.split(' ').length > 1) {
                                this.finalPosition = this.finalPosition.replace('top ', !cantBeBottom ? "bottom " : "");
                            } else {
                                this.finalPosition = this.finalPosition.replace('top', !cantBeBottom ? "bottom" : "right");
                            }
                        }
                    } else if (this.position === "bottom") {
                        if (cantBeLeft && !cantBeRight) {
                            this.finalPosition = "bottom right"
                        } else if (cantBeRight && !(cantBeLeft)) {
                            this.finalPosition = "bottom left"
                        } else {
                            this.finalPosition = this.position;
                        }
                        if (cantBeBottom) {
                            if (this.finalPosition.split(' ').length > 1) {
                                this.finalPosition = this.finalPosition.replace('bottom ', !cantBeTop ? "top " : "");
                            } else {
                                this.finalPosition = this.finalPosition.replace('bottom', !cantBeTop ? "top" : "left");
                            }
                        }
                    }


                    this.$refs.tooltipContent.style.removeProperty('top');
                    this.$refs.tooltipContent.style.removeProperty('right');
                    this.$refs.tooltipContent.style.removeProperty('left');
                    this.$refs.tooltipContent.style.removeProperty('bottom');

                    if (this.finalPosition.split(' ').length > 1) {
                        let splitPosition = this.finalPosition.split(' ')

                        this.$refs.tooltipContent.style[splitPosition[0]] = -height + 18 + "px";

                        this.$refs.tooltipContent.style[splitPosition[1]] = -width - 10 + "px";

                    } else if (this.finalPosition === "top" || this.finalPosition === "bottom") {

                        this.$refs.tooltipContent.style[this.finalPosition] = -height - 16 + "px";

                        this.$refs.tooltipContent.style.left = -width / 2 + this.$refs.tooltipContainer.getBoundingClientRect().width / 2 + "px";
                    } else if (this.finalPosition === "left" || this.finalPosition === "right") {
                        this.$refs.tooltipContent.style.top = -height / 2 + this.$refs.tooltipContainer.getBoundingClientRect().height / 2 + "px";

                        this.$refs.tooltipContent.style[this.finalPosition] = -width - 10 + "px";
                    }
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
.tooltip-container {
    display: inline-block;
    position: relative;

    .tooltip-content {
        position: absolute;
        z-index: 99;
        border-radius: 4px;
        white-space: nowrap;
        @apply p-2;

        &.top {
            top: 0;
        }
    }
}
</style>

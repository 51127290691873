<template>
    <div class="w-full min-h-[3rem] h-fit rounded border border-grey4 p-1 relative">
        <div class="w-full h-fit max-h-[25.5rem] overflow-y-auto scroll-light">
            <div ref="editable" class="w-[88%]" contenteditable="true" @input="onInput" @keypress="onKeypress"></div>
        </div>

        <div v-for="(file, index) in files" :key="index"
             class="mt-2 mr-2 bg-grey6 rounded-full w-fit text-white text-xs px-3.5 py-1 flex flex-row items-center justify-center">
            {{ file.filename }}
            <div class="cursor-pointer" @click="$emit('removedoc', index)">
                <icons-x-circle-outline class="w-4 h-4 ml-2.5"></icons-x-circle-outline>
            </div>
        </div>

        <div v-if="oversized" class="mt-2 text-[#F00] text-xs">
            Taille maximum (15 Mo) dépassée ! Votre fichier <br/>
            ne peut pas être envoyé. En savoir plus.
        </div>

        <div class="absolute" style="bottom:0.8rem;right:1.2rem;" @click="send">
            <icons-paper-plane-regular class="w-5 h-5 cursor-pointer"></icons-paper-plane-regular>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextInput",
    props: {
        files: {
            type: [Array, null],
            required: true
        },
        oversized: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        inputValue: ""
    }),
    methods: {
        send() {
            this.$emit("message", this.inputValue);

            this.inputValue = "";
            this.$refs.editable.innerText = "";
        },
        onInput(e) {
            this.inputValue = e.target.innerText;
        },
        onKeypress(e) {
            if (e.which === 13 || e.which === 10) {
                if (!e.shiftKey) {
                    e.preventDefault();
                    this.send();
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .text-input {
        resize: none;
    }

    [contenteditable] {
        outline: 0 solid transparent;
    }
</style>

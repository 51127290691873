<template>
    <footer>
        <FooterBase></FooterBase>

<!--        Commenté en attendant le WM-->
<!--        <FooterVilles></FooterVilles>-->
    </footer>
</template>

<style scoped>

</style>

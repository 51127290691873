import {defineStore} from 'pinia';

export const useConfigurationsStore = defineStore(
    {
        id: "configurations-store",
        state: () => ({
            configurations: []
        }),
        actions: {
            setConfig(config) {
                this.configurations.push(config);
            },
            getConfigs(configs) {
                return new Promise((resolve, reject) => {
                    let alreadySetConfigs = this.configurations.map((storedConfig) => storedConfig.slug);
                    let notSet = [];

                    configs.forEach((config) => {
                        if (!alreadySetConfigs.includes(config)) {
                            notSet.push(config)
                        }
                    })

                    if (notSet.length > 0) {
                        useNuxtApp().$axios.post('/config', {slugs: notSet}).then(({data}) => {
                            data.forEach((config) => {
                                this.setConfig(config);
                            })
                        }).catch((e) => {
                            console.error(e);
                            reject()
                        }).finally(() => {
                            resolve(this.configurations.filter((storedConfig) => configs.includes(storedConfig.slug)));
                        });
                    } else {
                        resolve(this.configurations.filter((storedConfig) => configs.includes(storedConfig.slug)));
                    }
                })
            }
        }
    }
)
export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"robots","content":"index,follow","hid":"robots"},{"name":"theme-color","content":"#FBECE3"},{"name":"format-detection","content":"telephone=no"},{"name":"google-adsense-account","content":"ca-pub-6036616750005990"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico","hid":"favicon"}],"style":[],"script":[{"hid":"nuxtConsent","id":"nuxtConsent","src":"/nuxtConsent.js","type":"text/javascript"},{"hid":"cookiebot","id":"Cookiebot","src":"https://consent.cookiebot.com/uc.js","data-cbid":"75e3d4fc-230c-41fa-be13-ac08fc7207dc","data-framework":"TCFv2.2","type":"text/javascript"},{"hid":"gtm","innerHTML":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MC27CKX');"}],"noscript":[],"title":"Maslow","htmlAttrs":{"lang":"fr"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false
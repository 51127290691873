<template>
    <div class="grid grid-cols-10 items-center justify-center w-full bg-grey1 py-2 min-h-10 sm:hidden">
        <!-- Conteneur vide pour centrer le message, c moche mais c comme ça -->
        <div class="col-span-2 md:hidden"></div>

        <!-- message -->
        <div v-if="message !== null" class="text-sm col-span-6 md:col-span-10 text-center" v-html="message"></div>
        <div v-else class="skeleton-loader col-span-6 md:col-span-10 h-6 rounded"></div>

        <!-- boutons panier et compte -->
        <div class="flex items-center justify-end divide-x-2 divide-grey4 col-span-2 md:hidden">
            <NuxtLink :to="{name: isLoggedIn ? 'mon-compte-mon-panier' : 'connexion'}"
                      class="flex gap-x-2 items-center hover:text-tangerine px-3 transition-colors"
            >
                <icons-shopping-cart-alt-outline :active="hasInShoppingCart" class="w-5"></icons-shopping-cart-alt-outline>
                <span class="block sm:hidden font-medium text-sm -tracking-[0.0175rem]">Mon panier</span>
            </NuxtLink>

            <NuxtLink :to="{name: isLoggedIn ? 'mon-compte' : 'connexion'}"
                      class="flex gap-x-2 items-center hover:text-tangerine px-3 transition-colors"
            >
                <icons-user-outline class="w-5"></icons-user-outline>
                <span class="block sm:hidden font-medium text-sm -tracking-[0.0175rem]">{{ isLoggedIn ? prenom : 'Mon compte' }}</span>
            </NuxtLink>
        </div>
    </div>
</template>

<script setup>
import { useUserStore } from '~/store/user';
import {storeToRefs} from "pinia";

const { isLoggedIn, prenom, hasInShoppingCart } = storeToRefs(useUserStore());

const props = defineProps({
    message: String
});
</script>

<style scoped lang="scss">

</style>

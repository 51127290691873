import moment from 'moment-timezone';
import 'moment/dist/locale/fr';

export default defineNuxtPlugin((nuxtApp) => {
    moment.locale('fr');
    moment.tz.setDefault("Europe/Paris");

    return {
        provide: {
            moment: moment
        }
    }
});